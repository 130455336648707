import React, { useState, useEffect } from 'react';
import { Header, Image, Button, Icon } from 'semantic-ui-react';
import { formatPhoneNumber } from 'react-phone-number-input'
import available from '../../assets/images/Check.png';
import userDummy from '../../assets/images/user-dummy.png';
import busy from '../../assets/images/busyIcon.png';
import { withRouter } from 'react-router-dom';
// import LexxButton from '../LexxButton/lexxbutton.jsx';
import { connect } from 'react-redux';
// import CarouselCardTask from "../LexxCarousel/CarouselCardTask";
// import deleteIcon from '../../assets/images/delete.png';
// import LexxMemberModal from '../LexxTeam/LexxMemberModal';
import { callFunctionalityUsed, changeStatus, getUserProfile } from '../apiCall';

/* This function is to show the user profile of the user
    Show the user their own profile
    Show other technician profile as well 
*/
function UserProfile(props) {

    const [form, setForm] = useState({ // different fields of the user profile
        name: "",
        title: "",
        aboutMe: "",
        city: "",
        country: "",
        speciality: [],
        phoneNum: "",
        status: "",
        inTeam: "",
        userId: "",
        tasks: [],
    });

    /* When the user clicks on edit Profile btn, redirect them to editProfile page */
    const editProfile = () => {
        props.history.push("/editProfile");
    }

    /* Function to get the profile of the logged in user or other person */
    const getProfile = () => {
        let userToBeRequested = props.otherProfile ? props.match.params.id : props.requesterId; // whose profile needs to be shown
        getUserProfile(userToBeRequested).then((response) => { // server call
            var profileData = response.data.data.user;
            setForm({
                name: profileData.name === undefined ? "" : profileData.name,
                title: profileData.title === undefined ? "" : profileData.title,
                profileImg: (profileData.profileImg === undefined || profileData.profileImg === "") ? userDummy : profileData.profileImg,
                aboutMe: profileData.aboutMe === undefined ? "" : profileData.aboutMe,
                city: profileData.location === undefined ? "" : profileData.location.city,
                country: profileData.location === undefined ? "" : profileData.location.country,
                speciality: profileData.speciality === undefined ? [] : profileData.speciality,
                phoneNum: profileData.phoneNum === undefined ? "" : profileData.phoneNum,
                status: profileData.status === undefined ? "" : profileData.status,
                inTeam: profileData.inTeam === undefined ? "" : profileData.inTeam,
                userId: profileData.userId === undefined ? "" : profileData.userId,
                tasks: profileData.tasks === undefined ? [] : profileData.tasks,
            });
        })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(getProfile, [props.otherProfile]); // get the profile and pass the props.otherProfile 


    /* this function is to track if the user clicked on call or SMS */
    const callMade = (type) => {
        if (type === "CALL") {
            window.location.href = "tel:" + form.phoneNum; // windows default location for phone call
        } else {
            window.location.href = "sms://" + form.phoneNum;
        }
        const data = { "toId": props.requesterId, "type": type };
        callFunctionalityUsed(data);
    }


    /* When the user changes the status from available to busy or visa-versa 
     @status - this is the updated status passed on from the btn
    */
    const statusChange = (status) => {
        changeStatus(status).then((response) => {
            getProfile();
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            <div className="user-profile-container">
                {!props.otherProfile ?
                    <div className="edit-icon-profile-container">
                        <div onClick={() => editProfile()} className="edit-icon-profile">
                            <Icon name="edit outline" /> Edit
                        </div>
                    </div>
                    : null
                }
                <div className="user-profile-image">
                    <div className="image-wrapper">
                        <Image src={form.profileImg} className="profile" />
                        {props.otherProfile ? form.status === "available" ?
                            <Image src={available} className="status" /> : <Image src={busy} className="status-busy" />
                            : null
                        }
                    </div>
                    <Header as="h2">{form.name}</Header>
                    <Header as="h5">{form.title}</Header>
                </div>
                <div className="user-profile-about">
                    <label> {!props.otherProfile ? "My Bio" : "About " + form.name}</label>
                    <p>
                        {form.aboutMe}
                    </p>
                </div>
                <div className="user-profile-about">
                    <label> Location </label>
                    <p>
                        {form.city}{", "} {form.country}
                    </p>
                </div>
                {!props.otherProfile ?
                    <>
                        <div className="user-profile-about">
                            <label> Status </label>
                            <div>
                                <Button attached='left' className={form.status === ('available' || "") ? "available-btn active" : "available-btn"} onClick={() => statusChange("available")}>Available</Button>
                                <Button attached='right' className={form.status === 'busy' ? "busy-btn active" : "busy-btn"} onClick={() => statusChange("busy")}>Busy</Button>
                            </div>
                        </div>
                        <div className="user-profile-about">
                            <label> Email </label>
                            <p>
                                {props.username}
                            </p>
                        </div>
                        <div className="user-profile-about">
                            <label> Phone Number </label>
                            <p>
                                {formatPhoneNumber(form.phoneNum)}
                            </p>
                        </div>
                    </>
                    : null}
                <div className="user-profile-about">
                    <label> Specialities </label>
                    <p>
                        {
                            form.speciality.map((speciality, i) => {
                                return (
                                    <span key={i}> {speciality} </span>
                                )
                            })
                        }
                    </p>
                </div>
            </div>

            {props.otherProfile ?
                <div className='user-profile-btn messageCall-container'>
                    <div className="App-header">
                        <Button onClick={() => callMade("CALL")}>Call</Button>
                        {/* <Button onClick={() => callMade("SMS")}>Message</Button> */}
                        {/* <Button>Invite to a Session</Button> */}
                    </div>
                </div>
                : null}
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        username: state.userInfo.userId,
        requesterId: state.userInfo.userId
    };
};

export default withRouter(connect(mapStateToProps)(UserProfile));