import * as React from "react";
import { Grid } from "semantic-ui-react";


const Root = (props) => (
    <>
        <Grid.Row>
            <div>{props.text}</div>
        </Grid.Row>
        {(props.children && props.children.length > 0) && props.children}
    </>
);

export default Root;
