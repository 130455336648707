import React from 'react';
import { Accordion, Icon } from 'semantic-ui-react';

const OptionSelector = ({
    contents,
    updateHash,
    clickedToc,
    handleOptionSelect
}) => {
    return (
        <div class='custom-modal '>
            <div class='option-side-bar'>
                <Accordion>
                    <Accordion.Content active={true}>
                        <ul className='sidebar__highlights'>
                            {contents
                                .filter(
                                    (highlight) =>
                                        highlight.content.text !==
                                        'Summary from search display'
                                )
                                .map((highlight, index) => (
                                    <div>
                                        {highlight.isOutLine === true && (
                                            <div
                                                className='tableOfContents'
                                                id={highlight.id}
                                                style={{
                                                    backgroundColor:
                                                        clickedToc ===
                                                        highlight.id
                                                            ? '#EEF5FC'
                                                            : ''
                                                }}
                                                onClick={() => {
                                                    handleOptionSelect(
                                                        highlight
                                                    );
                                                    updateHash(highlight);
                                                }}
                                            >
                                                <div>
                                                    <li
                                                        key={index}
                                                        className='sidebar__highlight'
                                                    >
                                                        <div>
                                                            <strong>
                                                                {
                                                                    highlight
                                                                        .comment
                                                                        .text
                                                                }
                                                            </strong>
                                                            {highlight.content
                                                                .text ? (
                                                                <blockquote
                                                                    className='sidebar-text'
                                                                    style={{
                                                                        color:
                                                                            clickedToc ===
                                                                            highlight.id
                                                                                ? '#034E91'
                                                                                : ''
                                                                    }}
                                                                >
                                                                    {
                                                                        highlight
                                                                            .content
                                                                            .text
                                                                    }
                                                                </blockquote>
                                                            ) : null}
                                                            {highlight.content
                                                                .image ? (
                                                                <div
                                                                    className='highlight__image'
                                                                    style={{
                                                                        marginTop:
                                                                            '0.5rem'
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            highlight
                                                                                .content
                                                                                .image
                                                                        }
                                                                        alt={
                                                                            'Screenshot'
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </li>
                                                </div>
                                                <div>
                                                    <Icon
                                                        name={'chevron right'}
                                                        className='arrow-icon'
                                                        style={{
                                                            color:
                                                                clickedToc ===
                                                                highlight.id
                                                                    ? '#034E91'
                                                                    : ''
                                                        }}
                                                        onClick={() => {
                                                            handleOptionSelect(
                                                                highlight
                                                            );
                                                            updateHash(
                                                                highlight
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                        </ul>
                    </Accordion.Content>
                </Accordion>
            </div>
        </div>
    );
};

export default OptionSelector;
