import React, { useEffect } from "react";
import Spinner from "./Spinner";
import { PdfLoader, PdfHighlighter, Highlight, Popup,
   AreaHighlight } from "react-pdf-highlighter";
import { connect } from 'react-redux';
import Sidebar from "./Sidebar";

const PdfHighlighterPage = (props) => {
  const HighlightPopup = null;
  let highlightText = [];
  let tableContents = [];
  
    const resetHash = () => {
        document.location.hash = "";
      };

    const getRectValues = (element) => {
      let rectValues = [];
      if(element.meta.id) {
        rectValues.push({
          height: element.meta.height,
          width: element.meta.width,
          x1: element.meta.x1,
          x2: element.meta.x2,
          y1: element.meta.y1,
          y2: element.meta.y2,
          pageNumber: element.meta.pageNumber
        });
      } else if (element.meta.length > 0) {
        for(let i =0; i < element.meta.length ; i ++) {
          rectValues.push({
            height: element.meta[i].height,
            width: element.meta[i].width,
            x1: element.meta[i].x1,
            x2: element.meta[i].x2,
            y1: element.meta[i].y1,
            y2: element.meta[i].y2,
            pageNumber: element.meta[i].pageNumber
          });
        }
      }
      return rectValues;
    };

    
 
    if(props.outline ) {props.outline.forEach(element => {
      tableContents.push(
      {
        comment :{
          emoji: '',
          text: '',
        },
        content: {
          text:element.heading_s,
        },
        position:{
         
          rects: getRectValues(element),
        pageNumber : element.meta.length > 0 ? element.meta[0].pageNumber : element.meta.pageNumber,
        },
        id: element.id.replace('/outline#', ''),
        isOutLine: true
      }
      );
    }); 
    tableContents.sort(function(a, b) {
      return a.position.boundingRect.y1 - b.position.boundingRect.y1;
    });
    tableContents.sort(function(a, b) {
      return a.position.boundingRect.pageNumber - b.position.boundingRect.pageNumber;
    });
  };
  

  let scrollViewerTo = (highlight) => {
    console.log(highlight);
  };

  const parseIdFromHash = () => 
    document.location.hash.slice("#highlight-".length);
  
  useEffect(()=>{
    window.addEventListener(
      "hashchange",
      scrollToHighlightFromHash,
      false
    );
  },[]);

  const scrollToHighlightFromHash = () => {
    const highlight = getHighlightById(parseIdFromHash());

    if (highlight) {
      scrollViewerTo(highlight);
    }
  };

  const getHighlightById =(id) => {
    let highlightContent = highlightText.find((highlight) => highlight.id === id);
    if(!highlightContent) {
      highlightContent = tableContents.find((highlight) => highlight.id === id);
    }
    return highlightContent;
  };

    return (
      <div className="App" style={{ display: "flex", height: "100vh" }}>
         
          <div
            style={{
              height: "100vh",
              width: "75vw",
              position: "relative",
            }}
          >
            <PdfLoader url={props.troubleshootingData.file_link} beforeLoad={<Spinner />}>
              {(pdfDocument) => (
                <PdfHighlighter
                  pdfDocument={pdfDocument}
                  onScrollChange={resetHash}
                  scrollRef={(scrollTo) => {
                    scrollViewerTo = scrollTo;
  
                    scrollToHighlightFromHash();
                  }}
                  highlightTransform={(
                    highlight,
                    index,
                    setTip,
                    hideTip,
                    viewportToScaled,
                    screenshot,
                    isScrolledTo
                  ) => {
                    const isTextHighlight = !Boolean(
                      highlight.content && highlight.content.image
                    );
  
                    const component = isTextHighlight ? (
                        <Highlight
                        isScrolledTo={isScrolledTo}
                        position={highlight.position}
                        comment={highlight.comment}
                      />
                    ) : (
                      <AreaHighlight
                        // isScrolledTo={isScrolledTo}
                        highlight={highlight}
                        // onChange={(boundingRect) => {
                        //   updateHighlight(
                        //     2,
                        //     { boundingRect: viewportToScaled(boundingRect) },
                        //     { image: screenshot(boundingRect) }
                        //   );
                        // }}
                      />
                    );
  
                    return (
                      <Popup
                        popupContent={<HighlightPopup {...highlight} />}
                        // onMouseOver={(popupContent) =>
                        //   setTip(highlight, (highlight) => popupContent)
                        // }
                        // onMouseOut={hideTip}
                        key={index}
                        children={component}
                      />
                    );
                  }}
                  highlights={highlightText}
                />
              )}
            </PdfLoader>
          </div>
        </div>
      );
}; 

const mapStateToProps = (state) => {
    return {
      searchResults: state.searchResults,
      troubleshootingData: state.troubleshootingData,
    };
};

export default connect(mapStateToProps)(PdfHighlighterPage);