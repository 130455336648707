import React, { useEffect }  from 'react';
import { Container, Card } from 'semantic-ui-react';
import { connect } from 'react-redux';
import LexxImage from '../global/LexxImage';
import LexxList from '../global/LexxList';
import LexxPara from '../global/LexxPara';
import LexxFiTable from '../global/LexxFiTable'
import Unknown from '../global/Unknown';
import LexxBreadcrumbs from '../LexxBreadcrumbs/LexxBreadcrumbs';
import LexxSessionToolbar from '../LexxSession/LexxSessionToolbar';
import LexxIconHeading from '../global/LexxIconHeading';
import { sessionUpdate } from '../Helper';

/* This component is used to display the steps in Troubleshooting and map it to different sections */
const TroubleshootingAction = (props) => {

    /* This function is to update the session with new data */
    const sendSessionData = () => {
        sessionUpdate();
    }

    useEffect(sendSessionData, []);
    
    /* This function is to call the respective section/component depending on the tag 
        @obj - obj is the tag data we get from backend
    */
    const getSection = (obj) => {
        let compGroup = obj.map((tagData) => {
            switch (tagData.tag) {
                case "ol":
                    return (<LexxList key={Math.random()} listData={tagData} bullet={false} troubleshooting={true} />);
                case "ul":
                    return (<LexxList key={Math.random()} listData={tagData} bullet={true} troubleshooting={true} />);
                case "img":
                    return (<LexxImage key={Math.random()} imageData={tagData} troubleshooting={true} />);
                case "video":
                    return (<LexxImage key={Math.random()} imageData={tagData} troubleshooting={true} />);
                case "p":
                    return (<LexxPara key={Math.random()} paraData={tagData} troubleshooting={true} />);
                case "table":
                    return <LexxFiTable key={Math.random()} tableData={tagData} troubleshooting={true}  />;
                default:
                    return Unknown;
            }
        });
        return (<>{compGroup}</>);
    };

    /* This function checks if step id is equal to the troubleshooting option */
    const actionData = props.troubleshootingData.data.find((step) => {
        return step.id === props.troubleshootingOption;
    })

    return (
        <div className='content-container'>
            <LexxBreadcrumbs />
            <Container className='ts-action-container'>
                <LexxIconHeading text="Here is some more information" />
                <div className='search-results-container marginL3'>
                    <Card fluid className='content-container-card'>
                        <Card.Content>
                            {getSection(actionData.action)}
                        </Card.Content>
                    </Card>
                </div>
            </Container>
            <LexxSessionToolbar />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        troubleshootingData: state.troubleshootingData, //troubleshooting data 
        troubleshootingOption: state.troubleshootingOption // different troubleshooting option
    };
};

export default connect(mapStateToProps)(TroubleshootingAction);