import * as React from "react";
import { Label } from 'semantic-ui-react';
import throttle from 'lodash.throttle';
import { getButtonClassFromState } from './lexxbutton.js';


// Container height must be at least 3em. This element will fill the size of the container

/* LexxButton is re-used at multiple places to display the button
   based on the props that is sent, either primary or secondary
*/
class LexxButton extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickThrottled = throttle(this.handleClick, 1000);
    }

    /* Click function is passed from the other component */
    handleClick() {
        this.props.act();
    }

    /* To stop the throttle */
    componentWillUnmount() {
        this.handleClickThrottled.cancel();
    }

    render() {
        let button_class = 'lexxbutton ' + getButtonClassFromState(this.props);
        let label_class = 'lexxlabel ' + getButtonClassFromState(this.props) + '-label';
        return (
            <button className={button_class} disabled={this.props.disabled} onClick={this.handleClickThrottled}>
                {
                    this.props.img ?
                        <Label className='label-image-class' as='a'>
                            <img className='invert-color' src={this.props.img} alt="Action"/>
                        </Label>
                        :
                        <Label className={label_class}>{this.props.text}</Label>
                }
            </button>
        );
    }
}

export default LexxButton;