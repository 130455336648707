import { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { geolocation } from '../../redux/actions';
import axios from "axios";

/* The component is for grabbing the location of the user and
    storing it in MongoDB */
class LexxGeolocation extends Component {
    constructor(props){
        super(props);
        this.state = {trackGeolocation: ""};
        this.onSuccess = this.onSuccess.bind(this); 
    }

    /* Function called when location permissions are granted by the user */
    onSuccess = (location) => {
        let loc = {
            lng: location.coords.longitude,
            lat: location.coords.latitude
        }
        this.props.geolocation(loc);
        axios.post("/addGeolocation", {
            ...loc,
            username: this.props.userInfo.username,
            timestamp: (new Date()).toUTCString() //Convert to UTC
        });
    }

    onError = (error) => {
        return;
    }

    /* When the component is mounted for the first time */
    componentDidMount = () => {

        /* every 5 mins call this function to grab the location */
        let trackGeolocation = setInterval( () => {
            if(this.props.userInfo.authenticated && "geolocation" in navigator){
                navigator.geolocation.getCurrentPosition(this.onSuccess, this.onError);
            } else {
                this.props.geolocation({
                    lng: "",
                    lat: ""
                });
            }
        }, 5*60*1000); // 5mins
        
        this.setState({
            trackGeolocation: trackGeolocation
        })
    }

    /* Clear the interval of 5 mins when the component is unmounted */
    componentWillUnmount = () => {
        clearInterval(this.state.trackGeolocation);
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
      userInfo: state.userInfo,
      location: state.geolocation
    }
  }

export default withRouter(connect(mapStateToProps, { geolocation })(LexxGeolocation)) ;