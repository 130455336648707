function colorName(solution_status) {
  if (solution_status === "SAVED") {
    return "saved";
  } else if (solution_status === "IN REVIEW") {
    return "inreview";
  } else {
    return "approved";
  }
}

export { colorName };