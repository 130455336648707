import React from 'react';
import LexxImage from './LexxImage';
import LexxAnchor from './LexxAnchor';

/* Component which renders Para which has different children:
 1. image 2. anchor 3. or Para
  1 & 2 calls the respective components.
 */
const LexxPara = (props) => {
    const mapTag = (obj) => {
        let compGroup = obj.tag_data.map((child, key) => {
            const LexxParaArray = ["AdditionalInfo:", "Tools:", "Spares:","AdditionalInfo: N/A", "Tools: N/A", "Spares: N/A", "Spares: NA", "Tools: NA", "AdditionalInfo: NA", "NA" ];
            if (child !== null && child.tag === 'img') {
                return <LexxImage key={key} imageData={child} troubleshooting={props.troubleshooting} />
            } else if (child !== null && child.tag === 'anchor') {
                return <LexxAnchor key={key} anchorData={child} />
            } else if (child !== null && !LexxParaArray.includes(child)) {
                const formattedText = child.replace(/\n/g, '<br />');
                return <p key={key} dangerouslySetInnerHTML={{ __html: formattedText }} />
            } else {
                return null;
            }
        });

        return (
            <React.Fragment>
                {compGroup}
            </React.Fragment>
        );
    };

    return (
        <>
            {mapTag(props.paraData)}
        </>
    );
};

export default LexxPara;