import React, { useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import Webcam from "react-webcam";

export const WebCamVideo = (props) => {
  const [capturing, setCapturing] = React.useState(false);
  const mediaRecorderRef = React.useRef(null);
  const webcamRef = React.useRef(null);
  const [recordedChunks, setRecordedChunks] = React.useState([]);
  const [isUploadVideo, setIsUploadVideo] = useState(false);

  const videoConstraints = {
    facingMode: "user",
  };

  const handleUploadVideo = async () => {
    const fileType = "video/mp4";
    if (recordedChunks.length) {
      const file = new Blob(recordedChunks,{
        type: fileType,
      });
      file.name = `${new Date()}.mp4`;
      file.lastModifiedDate = new Date();
      const myFile = new File([file], file.name, {
        type: file.type,
      });
      props.getRecordedVideo(myFile);
    }
  };

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStartCaptureClick = React.useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm"
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef,handleDataAvailable]);

  const handleStopCaptureClick = React.useCallback(() => {
    setIsUploadVideo(true);
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, setCapturing]);

  return (
    <Modal
        onClose={() => props.handleClose}
        open={props.showModal}
    >
      <Modal.Header>Capture Video </Modal.Header>
      <Modal.Content>
        <Webcam
          audio={false}
          height={"100%"}
          ref={webcamRef}
          width={"100%"}
          videoConstraints={videoConstraints}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button color="red"
        onClick={props.handleClose}
        >
          Cancel
        </Button>
        {capturing ? (
          <Button color="blue" onClick={handleStopCaptureClick}>Stop Capture</Button>
        ) : isUploadVideo ? (
          <Button color="blue" onClick={handleUploadVideo}>Upload</Button>
        ) :
        (
          <Button color="blue" onClick={handleStartCaptureClick}>Start Capture</Button>
        )}
      </Modal.Actions>
    </Modal>
  );
}
export default WebCamVideo;