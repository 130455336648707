import React from 'react';
import { List } from 'semantic-ui-react';
import LexxPara from './LexxPara';

/* Component to render the list for procedure */
const LexxList = (props) => {

    /* Function to map the list , catering to 3 tags 
        para, ol (ordered list) and ul (unordered list)
        also the function calls itself 
    */
    const handleList = (obj) => {
        let compGroup = obj.tag_data.map((child, key) => {
            let lsComp = child.tag_data.map((lsChild, keyChild) => {
                if (lsChild.tag === 'p') {
                    return (
                        <List.Item key={keyChild}>
                            <List.Content>
                                <LexxPara paraData={lsChild} troubleshooting={props.troubleshooting} />
                            </List.Content>
                        </List.Item>
                    );
                } else if (lsChild.tag === 'ul') {
                    return (
                        <List.Item key={keyChild}>
                            <List.Content>
                                <List.List>
                                    {handleList(lsChild)}
                                </List.List>
                            </List.Content>
                        </List.Item>
                    );
                } else if (lsChild.tag === 'ol') {
                    return (
                        <List.Item key={keyChild}>
                            <List.Content>
                                <List.List>
                                    {handleList(lsChild)}
                                </List.List>
                            </List.Content>
                        </List.Item>
                    );
                }else {
                    return null;
                }
            });
            return lsComp;
        });
        return (
            <React.Fragment>
                {compGroup}
            </React.Fragment>
        );
    }

    return (
        <List>
            {handleList(props.listData)}
        </List>
    );
};

export default LexxList;