import React from "react";
import { Card, Label } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  saveSessionData,
  saveSearchResults,
  saveTroubleshootingData,
  saveTroubleshootingOption,
  saveTroubleshootingStep,
  setSearchType,
  saveVisitedTroubleshootingSteps,
  saveSearchQuery,
  saveSessionUsers,
  storeDocDetail,
  storeOutline,
  storeFileLink,
  setFiltervalues,
  saveNumFound,
  addfilter,
  setActivePage
} from "../../redux/actions";
import { getSingleSession } from "../apiCall";
import userDummy from "../../assets/images/user-dummy.png";

/* This is the component to show "a" Session in carousel */
const SavedSessionCard = (props) => {
  /* This function is called when we need to display a session data
        every session is associated with an info type
    */
  const handleSessionType = () => {
    let displayTitle, displayType;
    let sessionDisplayData = props.sessionData.troubleshootingData;
    if (sessionDisplayData !== null) {
      switch (sessionDisplayData.info_type) {
        case "Fault isolation": // troubleshooting - FI
          [displayTitle, displayType] = [
            sessionDisplayData.id,
            "Troubleshooting",
          ];
          break;
        case "Examinations, tests & checks": // procedure
        case "proced":
          [displayTitle, displayType] = [sessionDisplayData.title, "Procedure"];
          break;
        case "Work Order": // work order
          [displayTitle, displayType] = [
            sessionDisplayData.title,
            "Work Order",
          ];
          break;
        case "Schematics": // schematics
          [displayTitle, displayType] = [
            sessionDisplayData.subCategory,
            "Schematics",
          ];
          break;
        case "Description": // Description
          [displayTitle, displayType] = [
            sessionDisplayData.subCategory,
            "Parts List",
          ];
          break;
        default:
          [displayTitle, displayType] = [
            sessionDisplayData?.title,
            "Reference",
          ];
      }
    } else {
      [displayTitle, displayType] = ["Search", "Search Page"];
    }

    /* This function is used to display the date in right format 
            @dateUTC - date in UTC 
        */
    const getDate = (dateUTC) => {
      return (
        dateUTC.getDate() +
        "/" +
        (dateUTC.getMonth() + 1) +
        "/" +
        dateUTC.getFullYear()
      );
    };

    /* This function is used to display the time in right format 
            @dateUTC - date in UTC 
        */
    const getTime = (dateUTC) => {
      if (dateUTC.getMinutes().toString().length === 1) {
        return dateUTC.getHours() + ":0" + dateUTC.getMinutes();
      } else {
        return dateUTC.getHours() + ":" + dateUTC.getMinutes();
      }
    };

    /* This components gets all the data for a session in the props 
            and this renders each field and displays it
        */
    return (
      <Card.Content>
        <Card.Meta className="font10">
          {getDate(new Date(props.sessionData.createTime)) +
            " " +
            getTime(new Date(props.sessionData.createTime))}
        </Card.Meta>
        <Card.Header className="font14 overflow-text">{`"${props.sessionData.searchQuery}"`}</Card.Header>
        {/* <Card.Meta className="font12 overflow-text marT10">
          <span className="label-session">Document:</span>
          <span className="value-session">{displayTitle}</span>
        </Card.Meta> */}
        <Card.Meta className="font12 marB10">
          <span className="label-session">Search Type:</span>
          <br />
          <span className="value-session">
            {!props.sessionData.isTroubleshooting
              ? "I want to lookup"
              : "I want to fix"}
          </span>
        </Card.Meta>

        {/* <Label
          circular
          className="collaborators"
          image={props.sessionData.owner.avatar}
        /> */}

        {props.sessionData.guests.map((collab_id, k) => {
          if (collab_id.avatar !== "") {
            return (
              <Label
                key={k}
                circular
                className="collaborators"
                image={collab_id.avatar}
              />
            );
          } else {
            return (
              <Label
                key={k}
                circular
                className="collaborators"
                image={userDummy}
              />
            );
          }
        })}
      </Card.Content>
    );
  };

  /* This function is called when a session needs to be loaded
        @sessionSate - the complete session data needed to load the session
    */
  const loadSession = (sessionState) => {
    let userDetails = [];
    props.saveSessionData({
      sessionID: sessionState.sessionId,
      isOwner: props.userId === sessionState.owner.userId ? true : false,
    });
    props.saveSearchQuery(sessionState.searchQuery);
    userDetails.push(sessionState.owner, ...sessionState.guests);
    props.saveSessionUsers(userDetails);
    //for detail page
    props.storeOutline(JSON.stringify(sessionState.outLineRedux));
    props.storeDocDetail(sessionState.docDetail ? sessionState.docDetail : {});
    props.storeFileLink(
      sessionState.fileLinkRedux ? sessionState.fileLinkRedux : ""
    );
    //for displaying results
    props.saveSearchResults(sessionState.searchResults);
    props.saveTroubleshootingData(sessionState.troubleshootingData);
    props.saveTroubleshootingOption(sessionState.troubleshootingOption);
    props.saveTroubleshootingStep(sessionState.troubleshootingStep);
    props.setSearchType(sessionState.isTroubleshooting);
    props.setFiltervalues(sessionState.filterlist?sessionState.filterlist:JSON.stringify([]));
    props.saveNumFound(sessionState.numfound);
    props.addfilter(sessionState.appliedfilter?sessionState.appliedfilter:JSON.stringify([]));
    props.setActivePage(sessionState.activepage?sessionState.activepage:1);
    props.saveVisitedTroubleshootingSteps(
      sessionState.visitedTroubleshootingSteps
    );
    //for bavigating to the particular route
    props.history.push(sessionState.routerPath);
  };

  /* This function is called when a session is clicked 
        @sessionID - session ID
    */
  const extractSession = (sessionID) => {
    if (props.isClickable) {
      getSingleSession(sessionID)
        .then((response) => {
          loadSession(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <Card
      className="carousel-card"
      onClick={() => extractSession(props.sessionData.sessionId)}
    >
      <Card.Content>{handleSessionType()}</Card.Content>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.userInfo.userId,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    saveSearchResults,
    saveTroubleshootingData,
    saveTroubleshootingOption,
    saveTroubleshootingStep,
    setSearchType,
    saveVisitedTroubleshootingSteps,
    saveSearchQuery,
    saveSessionData,
    saveSessionUsers,
    storeDocDetail,
    storeOutline,
    storeFileLink,
    setFiltervalues,
    saveNumFound,
    addfilter,
    setActivePage
  })(SavedSessionCard)
);
