import React, { useState, useEffect } from "react";
import { Container, Pagination, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import {
  saveSearchResults,
  saveTroubleshootingData,
  saveNumFound,
  addfilter,
  setActivePage,
} from "../../redux/actions";
import LexxCard from "../LexxCard/lexxcard";
import LexxButton from "../LexxButton/lexxbutton.jsx";
import LexxBreadcrumbs from "../LexxBreadcrumbs/LexxBreadcrumbs";
import axios from "axios";
import LexxIconHeading from "../global/LexxIconHeading";
import Loader from "../global/Loader";
import {
  saveSessionData,
  searchClicked,
  saveTaskId,
} from "../../redux/actions/index";
import { sessionCreate, sessionUpdate } from "../Helper";
import { getSearchResultData, search } from "../apiCall";
import KeycloakUserService from "../login/KeyCloakService";
import Filter from "../Filter/Filter";

/* The component is to show the search Results */
const LexxSearchResults = (props) => {
  const [isloading, setIsLoading] = useState(false);
  const [groupId, setgroupId] = useState(KeycloakUserService.getGroups());
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [openfilter, setOpenFilter] = useState(false);
  const SEARCH_RESULTS_FACTOR = 5; // number of results to show to user
  const searchText =
    'Great, You asked for "' +
    props.searchQuery +
    "\". Here's what we found!"; // when search results are more than 0.
  const noResult = "Based on the context of your query, no results were found."; // when there are no search results

  const [count, setCount] = useState(0); // to get more data for pagination
  const card_button_text = props.isTroubleshooting
    ? `Start Troubleshooting &#8594;`
    : `Look up Information &#8594;`;

  /* Function to create the session after search results */
  const sendSessionData = () => {
    if (props.isSearchClicked) {
      sessionCreate(props);
      props.searchClicked(false);
    }
  };

  useEffect(sendSessionData, []);

  /* mapping function from solr to UI for info type */
  const info_type_mapping = {
    "Examinations, tests & checks": {
      route: "procedure",
    },
    Description: {
      route: "procedure",
    },
    descript: {
      route: "procedure",
    },
    proced: {
      route: "procedure",
    },
    "Work Order": {
      route: "workorder",
    },
    "Fault isolation": {
      route: "faultisolation",
    },
    Schematics: {
      route: "partsbreakdown",
    },
  };

  /* Function to direct the user to the correct page based on info_type
        @payload - each result data
        get the entire result data for each result when clicked from server
    */
  const directToResultPage = (searchData) => {
    let payload = searchData.payload;
    axios.defaults.params = {};
    axios.defaults.params["username"] = props.userInfo.username;
    const data = { params: { id: payload["id"] } };

    getSearchResultData(`/${payload["info_type"]}`, data)
      .then((response) => {
        props.saveTroubleshootingData(response.data);
        if (payload["info_type"] === "workorder") {
          props.history.push(`/troubleshooting/issues/workorder/`);
        } else if (
          payload["info_type"] === "procedure" ||
          payload["info_type"] === "proced" ||
          payload["info_type"] === "description"
        ) {
          //saving payload itself to troubleshootingdata so that we can show that in PDF , here we r not using API call response
          props.saveTroubleshootingData(searchData);
          props.history.push(`/procedure/id=${payload["id"]}`);
        } else if (payload["info_type"] === "partsbreakdown") {
          props.history.push(`/schematic/id=${payload["id"]}`);
        } else {
          props.history.push(`/troubleshooting/issues/options/`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function pageChange(e, data) {
    props.setActivePage(data.activePage);
    window.scrollTo(0, 0);
    setPage(data.activePage);
    triggerSearch(data.activePage);
  }
  function filterCount() {
    let filter_string = "";
    if (props.newFilter != null) {
      if (JSON.parse(props.newFilter).length === 1) {
        let temp = JSON.parse(props.newFilter);
        filter_string = ": " + temp[0];
        return filter_string;
      } else if (JSON.parse(props.newFilter).length > 1) {
        let temp = JSON.parse(props.newFilter);
        filter_string = ": " + temp[0] + "(+" + (temp.length - 1) + ")";
        return filter_string;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  const handleapplyfilter = (filter) => {
    setIsLoading(true);
    
    axios.defaults.params = {};
    axios.defaults.params["username"] = props.userInfo.username;
    filter !== null
      ? props.addfilter(JSON.stringify(filter))
      : props.addfilter(JSON.stringify([]));
    console.log("search appilied");
    search(props.searchQuery, props.isTroubleshooting, filter,0,rows)
      .then((res) => {
        //sent to the backend
        // Clear docs with no data
        if (res && res.data && res.data.docs && res.data.docs.length) {
          for (let i = 0; i < res.data.docs.length; i++) {
            if (
              res.data.docs[i].data &&
              res.data.docs[i].data[0].length === 0
            ) {
              delete res.data.docs[i];
            }
          }
        }

        props.saveSearchResults(res.data.docs);
        props.saveNumFound(res.data.numFound);
        setPage(1);
        props.setActivePage(1);
        sessionUpdate();
        setOpenFilter(false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const triggerSearch = (page) => {
    setIsLoading(true);
    axios.defaults.params = {};
    axios.defaults.params["username"] = props.userInfo.username;
    let start = rows * page - rows;
    search(props.searchQuery, props.isTroubleshooting,JSON.parse(props.newFilter),start, rows)
      .then((res) => {
        //sent to the backend
        // console.log(res);
        // Clear docs with no data for lonestar only
        if (
          localStorage.getItem("projectGroup") === "/EDPRL" ||
          localStorage.getItem("projectGroup") === "/EDPRL_dev"
        ) {
          for (let i = 0; i < res.data.docs.length; i++) {
            if (JSON.parse(res.data.docs[i].data[0]).length == 0) {
              delete res.data.docs[i];
            }
          }
        }
        // Save and reset redux
        props.saveSearchResults(res.data.docs);
        sessionUpdate();
        setIsLoading(false);
        // props.history.push("/troubleshooting/issues");
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
        if (err.response.status === 403) {
          KeycloakUserService.doLogout();
        }
      });
  };
  if (isloading) {
    return <Loader />;
  } else {
    return (
      <div className="content-container home-container">
        <LexxBreadcrumbs />
        {props.searchResults === null ? null : props.searchResults.length ===
          0 ? (
          <Container className="search-results-page">
            <LexxIconHeading text={noResult} />
          </Container>
        ) : (
          <Container className="search-results-page">
            <div className="heading-filter-wrap">
              <LexxIconHeading text={searchText} />
           { localStorage.getItem('projectGroup') === '/EDPRG'? (<Button
                    className="filter-botton"
                    onClick={() => {
                      setOpenFilter(true);
                    }}
                  >
                    Filter{filterCount()}
               </Button>):null}
            </div>
            {openfilter && (
                  <Filter
                    applyfilter={(filter) => {
                      handleapplyfilter(filter);
                    }}
                    handleClose={(e) => {
                      setOpenFilter(false);
                    }}
                  />
                )}
            <div className="search-results-container marginL3">
              {props.searchResults.map((item, i) => {
                // if (i >= SEARCH_RESULTS_FACTOR * (1 + count)) {
                //   return null;
                // }
                item["payload"] = {
                  info_type: info_type_mapping[item["info_type"]].route,
                  id: item.id,
                  fault_Description: item.fault_Description,
                };
                return (
                  <LexxCard
                    key={i}
                    data={item}
                    button={card_button_text}
                    act={directToResultPage}
                    showDesc={false}
                    history = {props.history}
                    serachresultscard = {true}
                  />
                );
              })}
            </div>
            <div className="pagination-container">
              <Pagination
                activePage={props.activePage}
                onPageChange={(e, data) => pageChange(e, data)}
                totalPages={Math.ceil(props.numFound / rows)}
              />
            </div>
            {/* {SEARCH_RESULTS_FACTOR * (1 + count) < props.searchResults.length ? (
            <LexxButton
              primary={false}
              pressed={false}
              disabled={false}
              text={"Show more options"}
              act={() => {
                setCount(count + 1);
              }}
            />
          ) : null} */}
          </Container>
        )}
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    searchResults: state.searchResults,
    troubleshootingData: state.troubleshootingData,
    isTroubleshooting: state.isTroubleshooting,
    userInfo: state.userInfo,
    searchQuery: state.searchQuery,
    isSearchClicked: state.isSearchClicked,
    numFound: state.numFound,
    newFilter : state.newFilter,
    activePage: state.activePage,
  };
};

export default connect(mapStateToProps, {
  saveSearchResults,
  saveNumFound,
  saveTroubleshootingData,
  saveSessionData,
  searchClicked,
  saveTaskId,
  addfilter,
  setActivePage,
})(LexxSearchResults);
