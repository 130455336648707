import React,{useState,useEffect} from "react";
import { Button,Card} from "semantic-ui-react";
import { connect } from "react-redux";
import LexxBreadcrumbs from "../LexxBreadcrumbs/LexxBreadcrumbs";
import { sessionUpdate } from "../Helper";



function WorkOrderDisplay(props){
 const [array_sapentry,setArraySapentry] = useState([]);
 const sendSessionData = () => { 
  sessionUpdate();
}

useEffect(sendSessionData, []);

 const displaytext = {
  Title:"Work Order Description",
  Park_Name:"Park Name",
  Long_Text:"Long Description",
  WorkOrder_Description:"Order Type"
 }

 /*Array for the table */
 useEffect(()=>{
  setArraySapentry(sapdatafield())
 },[props.workorderdetails])
 function sapdatafield() {
    let temparray = {};
    let workorder = JSON.parse(props.workorderdetails);
    workorder.park_name?temparray.Park_Name = workorder.park_name:temparray.Park_Name="No data available";
    workorder.WorkOrder_Description?temparray.WorkOrder_Description = workorder.WorkOrder_Description:temparray.WorkOrder_Description ="No data available";
    workorder.title?temparray.Title = workorder.title:temparray.Title="No data available";
    workorder.long_text?temparray.Long_Text = workorder.long_text:temparray.Long_Text ="No data available";

    return temparray;
  };

    return(
      <div
      className="  home-container"
      style={{ overflowY: "none", marginTop: "52px" }}
    >
      <LexxBreadcrumbs />
      <div className="table-display">
        {console.log(JSON.parse(props.workorderdetails))}
           <table className="key-value-table">
              <tbody>
                {Object.entries(array_sapentry).map(([key, value]) => (
                  <tr key={key}>
                    <td className="key-cell">
                      {displaytext[key]}
                    </td>
                    <td className="value-cell">
                     {value}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      isTroubleshooting: state.isTroubleshooting,
      userInfo: state.userInfo,
      workorderdetails: state.workorderdetails
    };
  };
  
  export default connect(mapStateToProps, {
 
  })(WorkOrderDisplay);