import * as React from "react";
import { Icon } from "semantic-ui-react";
/* This component is for the search bar */
class LexxSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "", // search query string
    };
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchClick = this.searchClick.bind(this);
  }


  /* Function called 
        When enter key is pressed , search the solr 
    */
  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.props.act(this.state.searchString);
    }
  }

  /* Update the search String when user types */
  handleChange(event) {
    this.setState({
      searchString: event.target.value,
    });
  }

  /* Function called 
        When btn is clicked , search the solr 
    */
  searchClick() {
    this.props.act(this.state.searchString);
  }

  render() {

    return (
      <div>
        <input
          className="search-input"
          onChange={(e) => this.handleChange(e)}
          onKeyPress={(e) => this.handleKeyPress(e)}
          value={this.state.searchString}
          placeholder="Search"
        />
        <Icon name="arrow right" onClick={() => this.searchClick()} />
      </div>
    );
  }
}

export default LexxSearchBar;
