import * as React from "react";
import { Icon, Message } from "semantic-ui-react";

const Warning = (props) => (
    <Message negative icon>
        <Icon name="exclamation triangle"/>
        <Message.Content>
            <Message.Header>Warning</Message.Header>
            {(props.children && props.children.length > 0) && props.children}
        </Message.Content>
    </Message>
);

export default Warning;
