import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import { Container, Header, Card } from 'semantic-ui-react';
import { Container, Header, Accordion, Icon, Card } from "semantic-ui-react";
import LexxImage from "../global/LexxImage";
import LexxList from "../global/LexxList";
import LexxPara from "../global/LexxPara";
import LexxTable from "../global/LexxTable";
import Unknown from "../global/Unknown";
import S1000D from "../s1000d/S1000D";
import LexxBreadcrumbs from "../LexxBreadcrumbs/LexxBreadcrumbs";
import LexxSessionToolbar from "../LexxSession/LexxSessionToolbar";
import Loader from "../global/Loader";
import { sessionUpdate } from "../Helper";
import { getProcedure } from "../apiCall";
import KeycloakUserService from "../login/KeyCloakService";

import PdfHighlighterPage from "./pdfHighlighter/PdfHighlighterPage";

/* The component is used for displaying the Procedure result */
const Procedure = (props) => {
  const [groupId, setgroupId] = useState(KeycloakUserService.getGroups());
  const [procedure, setProcedure] = useState(null); // this will hold the procedure data
  const [activeIndex, setActiveIndex] = useState(0); // to know what heading is active in the accordion
  const [isloading, setIsLoading] = useState(false); // to show loader or not - true/false
  const isSuzlon =
    localStorage.getItem("projectGroup") === "/EDPRS" ||
    localStorage.getItem("projectGroup") === "/EDPRS_dev";
  useEffect(() => {
    getProcedureData(props.match.params.id); // API call to get the procedure data for a specific id on refresh
  }, [props.match.params.id]);

  const sendSessionData = () => {
    sessionUpdate(); // update the session with the new data
  };

  useEffect(sendSessionData, []);

  /* This function is for calling the backend to get the procedure data and setting it */
  const getProcedureData = (pid) => {
    setIsLoading(true);
    const data = { params: { id: pid } }; // id of procedure
    getProcedure(data)
      .then((res) => {
        setProcedure({
          title: res.data.title,
          data: res.data.data,
          file_link: res.data.file_link,
        }); //setting the data
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  };

  /* When a section is clicked in the procedure this function is called
        @Accordion ID is updated as we click on the title
    */
  const onClickSection = (e, props) => {
    let sectionIndex = props.index;
    let newActiveIndex = activeIndex === sectionIndex ? -1 : sectionIndex;
    setActiveIndex(newActiveIndex);
  };

  /* This function is to render the section for procedure 
        @data - the procedure with tags and their cooresponding data
    */
  const renderSection = (data, index) => {
    let sectionName = data.section_name.replace(/(^[0-9]+)/g, "").trim();
    let compGroup = data.section_data.map((tagData) => {
      switch (tagData.tag) {
        case "ol":
          return (
            <LexxList
              key={Math.random()}
              listData={tagData}
              bullet={false}
              troubleshooting={false}
            />
          );
        case "ul":
          return (
            <LexxList
              key={Math.random()}
              listData={tagData}
              bullet={true}
              troubleshooting={false}
            />
          );
        case "img":
          return (
            <LexxImage
              key={Math.random()}
              imageData={tagData}
              troubleshooting={false}
            />
          );
        case "p":
          return (
            <LexxPara
              key={Math.random()}
              paraData={tagData}
              troubleshooting={false}
            />
          );
        case "table":
          return (
            <LexxTable
              key={Math.random()}
              tableData={tagData}
              troubleshooting={false}
            />
          );
        default:
          return Unknown;
      }
    });
    return (
      <>
        <Accordion.Title
          active={activeIndex === index}
          index={index}
          onClick={onClickSection}
        >
          <Header as="h4" className="procedure-accordion">
            <Icon name="dropdown" />
            {sectionName}
          </Header>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === index} className="marginL2">
          {compGroup}
        </Accordion.Content>
      </>
    );
  };

  /* This function is to render accordion under each section 
        @data - so we have to split the data based on section and that's what we are doing here
    */
  const renderAccordion = (data) => {
    let sections = [];
    for (let index = 1; index <= Object.keys(data).length; index++) {
      sections.push(renderSection(data[`section ${index}`], index)); // each section has the tags
    }
    return <Accordion>{sections}</Accordion>;
  };

  const renderBasedongroup = () => {
    if (
      localStorage.getItem("projectGroup") === "/EDPRV" ||
      localStorage.getItem("projectGroup") === "/EDPRV_dev"
    ) {
      return (
        <Card.Content>
          {/* <S1000D data={procedure.data}/> */}
          {renderAccordion(procedure.data)}
        </Card.Content>
      );
    } else if (
      localStorage.getItem("projectGroup") === "/EDPRS" ||
      localStorage.getItem("projectGroup") === "/EDPRS_dev"
    ) {
      return (
        <Card.Content
          style={
            localStorage.getItem("projectGroup") === "/EDPRS" ||
            localStorage.getItem("projectGroup") === "/EDPRS_dev"
              ? {
                  height: "72vh",
                  width: "  195vh",
                  overflowY: "scroll",
                }
              : {}
          }
        >
          <S1000D data={procedure.data} />
          {/* {renderAccordion(procedure.data)} */}
        </Card.Content>
      );
    } else {
      return (
        <Card.Content>
          <S1000D data={procedure.data} />
          {/* {renderAccordion(procedure.data)} */}
        </Card.Content>
      );
    }
  };

  if (isloading) {
    return <Loader />;
  } else {
    return (
      <>
        {localStorage.getItem("projectGroup") === "/EDPRL" ||
        localStorage.getItem("projectGroup") === "/EDPRL_dev" ? (
          <div className="content-container">
            <LexxBreadcrumbs />
            <PdfHighlighterPage />
          </div>
        ) : (
          //if its suzlon , then we need to avoid the scroll bar.
          <div
            className="content-container"
            style={{
              height:
                localStorage.getItem("projectGroup") === "/EDPRS" ||
                localStorage.getItem("projectGroup") === "/EDPRS_dev"
                  ? " 70vh"
                  : "",
            }}
          >
            <LexxBreadcrumbs />
            <Container
              id={
                localStorage.getItem("projectGroup") === "/EDPRS" ||
                localStorage.getItem("projectGroup") === "/EDPRS_dev"
                  ? "container-class"
                  : ""
              }
            >
              {procedure !== null ? (
                <Card
                  fluid
                  className="content-container-card procedure-wrapper"
                  id={
                    localStorage.getItem("projectGroup") === "/EDPRS" ||
                    localStorage.getItem("projectGroup") === "/EDPRS_dev"
                      ? "forMarginLeft"
                      : ""
                  }
                >
                  <Card.Header>
                    <Header as="h3" className="procedure-title-text">
                      {procedure.title}
                    </Header>
                  </Card.Header>
                  {/* <Card.Content> */}
                  {/* <S1000D data={procedure.data}/> */}
                  {/* {renderAccordion(procedure.data)} */}
                  {/* </Card.Content> */}
                  {renderBasedongroup()}
                </Card>
              ) : null}
            </Container>
          </div>
        )}
        {/* <LexxSessionToolbar /> */}
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    searchResults: state.searchResults,
    troubleshootingData: state.troubleshootingData,
  };
};

export default connect(mapStateToProps)(Procedure);
