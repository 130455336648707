import React from 'react';
import { Grid } from 'semantic-ui-react';

/* Component to just dump the text in html if we don't know the tag */
const Unknown = (props) => {
    return (
        <>
            <Grid.Row>
                <div>{props.text}</div>
            </Grid.Row>
            {(props.children && props.children.length > 0) && props.children}
        </>
    );
};

export default Unknown;