import React from 'react';
import { Image } from 'semantic-ui-react';
import TechnicianIcon from '../../assets/images/technician.png';


/* This component is used where the design showcases technician image
    along with some message for eg : well done ! 
*/
const LexxIconHeading = (props) => {

    return (
        <>
            <div className="icon-text-wrapper">
                <Image src={TechnicianIcon} className="tech-icon" />
                <p className='page-header-text'>
                    {props.text}
                </p>
            </div>
        </>
    );
};

export default LexxIconHeading;