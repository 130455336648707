import * as React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

class Reference extends React.Component {

    constructor(props) {
        super(props);
        this.onReferenceClick = this.onReferenceClick.bind(this);
    }

    onReferenceClick() {
        // Don't redirect if it's the same page (should never happen)
        if (this.props.dm_code_ref === this.props.match.params.dm_code) {
            return;
        }
        axios.get("/infotype", {params: {id: this.props.dm_code_ref}})
        .then((response) => {
            this.props.history.push(`/${response.data}/id=${this.props.dm_code_ref}`);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    render() {
        return (
            <>
                {/*<a onClick={() => this.onReferenceClick()}>
                    Click here for the reference: {this.props.dm_code_ref}
                </a>*/}
                {(this.props.children && this.props.children.length > 0) && this.props.children}
            </>
        );
    }

}

export default withRouter(Reference);
