import firebase from "firebase/app";
import "firebase/messaging";

/* Config for firebase notification */
const config = {
    apiKey: "AIzaSyDYGD0oEFyUl3aZedSkUbXfLWNtganxpfo",
    authDomain: "sales-energy-9315e.firebaseapp.com",
    projectId: "sales-energy-9315e",
    storageBucket: "sales-energy-9315e.appspot.com",
    messagingSenderId: "1084088183421",
    appId: "1:1084088183421:web:56140f37fbe5a2b3af9f14"
};

firebase.initializeApp(config);

export default firebase;
