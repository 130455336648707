import React from 'react';
import { Container, Image } from 'semantic-ui-react';
import LexxBreadcrumbs from '../LexxBreadcrumbs/LexxBreadcrumbs';
import Confetti from 'react-confetti';
import TechnicianIcon from '../../assets/images/technician.png';
import { setSearchType } from '../../redux/actions';
import { connect } from 'react-redux';
import LexxButton from '../LexxButton/lexxbutton.jsx';

/* Component to show a message of thank you based on the feedback provided by user */
const ThankYouHome = (props) => {

    var message = props.feedback ? "Great, thank you!" : "Thank you, we will look into it." // positive or negative feedback, message will change

    /* Function when the user clicks on the btn 
     @isTroubleshooting: true/false based on if the user wants to search for troubleshooting or WI/WO 
     */
    const handleClick = (isTroubleshooting) => {
        props.setSearchType(isTroubleshooting);
        props.history.push({
            pathname: '/troubleshooting'
        });
    };

    return (
        <div className="content-container home-container thankyou-outer-container">
            <LexxBreadcrumbs />
            <Container className='thankyou-inner-container'>
                <div className="thankyou-wrapper">
                    <Image src={TechnicianIcon} className="tech-icon" />
                    <p className='page-header-text'>
                       {message}
                     </p>
                    <p className='section-body-text marginL3'>Is there anything else I can help you with?</p>
                </div>
            </Container>
            <div className='thankyou-btn troubleshoot-btn'>
                <LexxButton
                    primary={true}
                    text="I want to troubleshoot"
                    act={() => handleClick(true)}
                />
            </div>
            <div className='thankyou-btn'>
                <LexxButton
                    primary={false}
                    text="Look Up Information"
                    act={() => handleClick(false)}
                />
            </div>
            {props.feedback ? 
                <Confetti
                    recycle={false}
                />
                : null
            }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        feedback: state.feedback
    };
};

export default connect(mapStateToProps, { setSearchType })(ThankYouHome);