import React from 'react';
import { Table } from 'semantic-ui-react';
import LexxPara from './LexxPara';

/* Component to render the Table for Procedure */
const LexxTable = (props) => {
    
    /* Function to map the tags for Table,
        loops over tr, td and then it renders cell.
    */
    const mapTag = (obj) => {
        let compGroup = [];
        obj.tag_data.forEach((child) => {
            let cellData = [];
            child.tag_data.forEach((tr) => {
                if (tr.tag_data.length > 0) {
                    tr.tag_data.forEach((td) => {
                        if (td.tag === 'p') {
                            cellData.push(<Table.Cell className="table-data" key={Math.floor((Math.random() * 1000) + 1)}> <LexxPara paraData={td} troubleshooting={props.troubleshooting} /></Table.Cell>);
                        }
                    });
                } else {
                    cellData.push(<Table.Cell className="table-data" key={Math.floor((Math.random() * 1000) + 1)} />);
                }
            });
            compGroup.push(<Table.Row key={Math.floor((Math.random() * 1000) + 1)}>{cellData}</Table.Row>);
        });
        return (<React.Fragment>{compGroup}</React.Fragment>);
    };
    
    return (
        <Table celled>
            <Table.Body>
                {mapTag(props.tableData)}
            </Table.Body>
        </Table>
    );
};

export default LexxTable;