import * as React from "react";
import { Icon, Message } from "semantic-ui-react";

const Note = (props) => (
    <Message color="blue" icon>
        <Icon name="thumbtack"/>
        <Message.Content>
            <Message.Header>Note</Message.Header>
            {(props.children && props.children.length > 0) && props.children}
        </Message.Content>
    </Message>
);

export default Note;
