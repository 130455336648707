import React, { useState } from "react";
import { Button, Menu } from "semantic-ui-react";
import AllSteps from "./Container";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { addSolution, addSteps, addStepCollection } from "../../redux/actions";
import { connect } from "react-redux";
import { updateSolution, saveEditedStep, deleteSteps } from "./../apiCall";
import { isMobile } from "react-device-detect";
import {HTML5Backend} from "react-dnd-html5-backend"

const SolutionSummary = (props) => {
  const [showSubmitAlert, setShowSubmitAlert] = useState(false);
  const [buttonEnbale, setButtonEnable] = useState(false);

  const handleSubmitButtonClick = async() => {
    setShowSubmitAlert(true);
    await submitSolution();
    // setting to true to display alert and redirect to New Solutions

    setTimeout(() => {
      props.history.push(`/newsolutions`);
    }, 3000);
  };

  const submitSolution = async()=>{
    setButtonEnable(true);
    //Update Solution details
    await updateSolutionDetails(); 

    //Save steps by solution
    await saveStepsBySolution();
    //Delete step collection
   await props.deleteStepsCollection.forEach((step) => {
     deleteSteps(props.solutionId, step.stepId);
    });

  }

  const sortSteps = (steps) => {
    steps.sort(function (a, b) {
      return a.order_id - b.order_id;
    });
  };

  const updateSolutionDetails = async() => {
    const solutionDetails = {
      faultTitle: props.title,
      faultCode: props.code,
      faultDescription: props.description,
      solutionId: props.solutionId,
      solutionStatus: "inreview",
    };
    await updateSolution(solutionDetails);
  }

  const saveStepsBySolution = async() => {
    sortSteps(props.steps);
    const editedStepIds = getUniqueIds(props.editStepIds);
    for(let i = 0; i < props.steps.length; i++) {
      const step = props.steps[i];
      for (let j = 0; j < editedStepIds.length; j++) {
        const stepId = editedStepIds[j];
        if (step.stepId === stepId) {
          const formData = createFormData(step);
          await saveEditedStep(props.solutionId, formData, stepId);
        }
      }
    }
  };

  const getUniqueIds = (editStepIds) => {
    return editStepIds.filter(
      (item, index) => editStepIds.indexOf(item) === index
    );
  };

  const createFormData = (step) => {
    const formData = new FormData();
    let stepDetail;
    stepDetail = {
      title: step.title,
      description: step.description,
      tools: step.tools,
      spares: step.spares,
      order_id: step.order_id,
      _id: step.stepId,
    };
    formData.append("data", JSON.stringify(stepDetail));
    return formData;
  };

  let submitAlertClass = showSubmitAlert
    ? "modal-alert animate"
    : "modal-alert";
  let submitAlertStyles = showSubmitAlert
    ? {}
    : { opacity: 0, visibility: "hidden", pointerEvents: "none" };

  return (
    <div className="session-summary">
      {showSubmitAlert && <div
        className={submitAlertClass}
        style={submitAlertStyles}
        variant="success"
      >
        <div className="modal-alert-icon">
          <img
            className="PopUpIcon"
            src={require("../../assets/images/Shape 1.png")}
            alt=""
          />
        </div>

        <div className="model-alert-msg">
          <h4 className="Heading">Solution submitted</h4>
          <p className="Para">It will now be reviewed by a senior technician</p>
        </div>
      </div>}
      <div className="main-heading">
        <h2>Summary</h2>
      </div>
      <h4>Fault title</h4>
      <p>{props.title}</p>
      <h4>Fault code(If known)</h4>
      <p>{props.code}</p>
      <h4>Fault description</h4>
      <p>{props.description}</p>
      <div className="hr-line"></div>
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        {props.steps.length > 0 && <AllSteps steps={props.steps} />}
      </DndProvider>
      <Menu borderless fixed="bottom" id="menu">
        <Menu.Item position="left">
          <Button
            className="secondarybtn"
            onClick={() => props.history.goBack()}
          >
            Back
          </Button>
        </Menu.Item>
        <Menu.Item position="right">
          <Button
            className="primarybtn"
            disabled={buttonEnbale}
            onClick={() => handleSubmitButtonClick()}
          >
            Submit solution
          </Button>
        </Menu.Item>
      </Menu>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    title: state.solution.title,
    code: state.solution.code,
    description: state.solution.description,
    solutionId: state.solution.solutionId,
    steps: state.steps,
    stepsCollection: state.stepsCollection,
    editStepIds: state.editStepIds,
    deleteStepsCollection: state.deleteStepsCollection,
  };
};

export default connect(mapStateToProps, {
  addSteps,
  addStepCollection,
  addSolution,
})(SolutionSummary);
