/* This function takes props based on the button type 
    and returns the classname for CSS
*/
function getButtonClassFromState(props){
    let classname = '';
    if(props.primary){
        classname += 'primary'
    } else {
        classname += 'secondary'
    }
    if(props.pressed){
        classname += '-pressed'
    }
    if(props.disabled){
        classname += '-disabled'
    }
    return classname
}


export { getButtonClassFromState }