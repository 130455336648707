import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LexxSchematicContainer from './LexxSchematicContainer';
import LexxBreadcrumbs from '../LexxBreadcrumbs/LexxBreadcrumbs';
import { sessionUpdate } from '../Helper';

/* This is the parent component to show the schemantics */
const LexxSchematicView = (props) => {

    /* Update the session */
    const sendSessionData = () => { 
        sessionUpdate();
    }

    useEffect(sendSessionData, []);

    const img_src = props.troubleshootingData.src[0]; // get the image url

    return (
        <div className="content-container home-container">
            <LexxBreadcrumbs />
            <div className="schematic-container">
                <LexxSchematicContainer img_src={img_src} partsbreakdown={props.troubleshootingData} />
            </div>
        </div>
    );

};

const mapStateToProps = (state) => {
    return {
        troubleshootingData: state.troubleshootingData
    };
};

export default connect(mapStateToProps, {})(LexxSchematicView);