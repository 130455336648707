import React, { useEffect } from "react";
import PdfHighlighterPage from "../pdfHighlighter/PdfHighlighterPage.js";
import LexxBreadcrumbs from "../LexxBreadcrumbs/LexxBreadcrumbs";
import { sessionUpdate } from "../Helper.js";
function NewProcedure() {
  const sendSessionData = () => {
    sessionUpdate(); // update the session with the new data
  };

  useEffect(sendSessionData, []);
  return (
    <div
      className="  home-container"
      style={{ overflowY: "none", marginTop: "52px" }}
    >
      <LexxBreadcrumbs />
      <PdfHighlighterPage />
    </div>
  );
}

export default NewProcedure;
