import React, { useState } from "react";
import { Button, Form, Menu } from "semantic-ui-react";
import { connect } from "react-redux";
import { addSolution, solutionNotSaved, resetSolutionFault } from "../../redux/actions";
import { saveSolution, updateSolution, deleteSolution } from "../apiCall";

const AddSolutionsFaultDetails = (props) => {
  const [form, setForm] = useState( props.solution ? props.solution : {
              title: "",
              code: "",
              description: "",
            });
  const [error, setError] = useState("");
  const [characterCount, setCharacterCount] = useState(0);

  /* Call this function when there is any change to the form */
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    if(e.target.name === "description"){
      setCharacterCount(e.target.value.length);
    }
  };

  /* This function is called when the user has made edits,
     it creates a formData obj from the fields
     also function to take the user to add solution steps page
  */
  const addFaultDetails = async() => {
    if(props.solution) {
      //update already saved solution details
      updateSolutionDetails();
    } else if (form.title.trim().length > 0) {
      props.solutionNotSaved(true);
      // save new solution details
      saveSolutionDetails();
    } else {
      setError("Fault Title cannot be empty");
    }
  };

  // update already saved solution details
  const updateSolutionDetails = async() => {
    const solution = {
      faultTitle: form.title,
      faultCode: form.code,
      faultDescription: form.description,
      solutionId: props.solution.solutionId,
    };
    await updateSolution(solution);
    var formData = {
      title: form.title,
      code: form.code,
      description: form.description,
      solutionId: props.solution.solutionId,
    };
    props.addSolution(formData);
    props.history.push("/addsolutionssteps");
  }

  //Save new solution details
  const saveSolutionDetails = async() => {
    var formData = {
      title: form.title,
      code: form.code,
      description: form.description,
    };
    const solution = {
      faultTitle: form.title,
      faultCode: form.code,
      faultDescription: form.description,
    };
    await saveSolution(solution).then((response) => {
      formData.solutionId = response.data.data.solution_id;
     });
    props.addSolution(formData);
    props.history.push("/addsolutionssteps");
  }

  const handleCancel = async() => {
    if(props.isSolutionNotSaved && props.solution.solutionId) {
      await deleteSolution(props.solution.solutionId);
    }
    props.resetSolutionFault(true);
    props.history.goBack();
  }

  return (
    <div className="session-summary">
      <div className="main-heading">
        <h2>Add a solution</h2>
      </div>
      <div className="sub-heading">
        <h3> Add fault details</h3>
      </div>
      <Form>
        {error.length > 0 && (
          <div className="profile error" id="error">
            <p>{error}</p>
          </div>
        )}
        <Form.Field required>
          <p className="form-label">Fault title *</p>
          <input
            name="title"
            placeholder="Fault title"
            value={form.title}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <p className="form-label">Fault code (if known)</p>
          <input
            name="code"
            placeholder="Fault code"
            value={form.code}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <p className="form-label">Fault description</p>
          <textarea
            id="falutDescription"
            placeholder="Fault description"
            name="description"
            maxLength={250}
            value={form.description}
            onChange={handleChange}
            rows="4"
          />
          <p className="mandatory-label">{characterCount}/250 Characters</p>
        </Form.Field>
        <p className="mandatory-label">* Is mandatory</p>
        <Menu borderless fixed="bottom" id="menu">
          <Menu.Item position="left">
            <Button
              className="secondarybtn"
              onClick={()=>{handleCancel()}}
            >
              Cancel
            </Button>
          </Menu.Item>
          <Menu.Item position="right">
            <Button
              type="button"
              className="primarybtn"
              disabled={form.title.length === 0}
              onClick={() => {
                addFaultDetails();
              }}
            >
              Continue
            </Button>
          </Menu.Item>
        </Menu>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    solution: state.solution,
    isSolutionNotSaved: state.isSolutionNotSaved,
  };
};

export default connect(mapStateToProps, { addSolution, solutionNotSaved, resetSolutionFault })(
  AddSolutionsFaultDetails
);
