import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LexxSessionToolbar from '../LexxSession/LexxSessionToolbar';
import { Grid, Header, Table } from "semantic-ui-react";
import LexxBreadcrumbs from '../LexxBreadcrumbs/LexxBreadcrumbs';
import { sessionUpdate } from '../Helper';


/* This component is for the Work Order to display all the fields */
const LexxWorkorder = (props) => {

    const workorder = props.troubleshootingData; // Work Order object with all details

    /* Update the session when user lands to work order page */
    const sendSessionData = () => { 
        sessionUpdate();
    }

    useEffect(sendSessionData, []);


    /* Table is used to show all the fields of the Work Order */
    const getTable = () => {
        return (
            <>
                <Grid.Column mobile={16} tablet={14} computer={12}>
                    <div style={{ overflowX: "auto" }}>
                        <Table structured definition>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell collapsing>
                                        Description
                                    </Table.Cell>
                                    <Table.Cell className="table-data">{workorder.title}</Table.Cell>
                                </Table.Row>
                                {workorder.series !== "" ?
                                    <Table.Row>
                                        <Table.Cell collapsing>
                                            Park Name
                                    </Table.Cell>
                                        <Table.Cell className="table-data">{workorder.series}</Table.Cell>
                                    </Table.Row>
                                    : null}
                                {workorder.wtg_name !== "" ?
                                    <Table.Row>
                                        <Table.Cell collapsing>
                                            Turbine Name
                                    </Table.Cell>
                                        <Table.Cell className="table-data">{workorder.wtg_name}</Table.Cell>
                                    </Table.Row>
                                    : null}
                                {workorder.fault_Code !== "" ?
                                    <Table.Row>
                                        <Table.Cell collapsing>
                                            Fault Code
                                    </Table.Cell>
                                        <Table.Cell className="table-data">{workorder.fault_Code}</Table.Cell>
                                    </Table.Row>
                                    : null}
                                {workorder.fault_Name !== "" ?
                                    <Table.Row>
                                        <Table.Cell collapsing>
                                            Fault Name
                                    </Table.Cell>
                                        <Table.Cell className="table-data">{workorder.fault_Name}</Table.Cell>
                                    </Table.Row>
                                    : null}
                                {workorder.fault_Description !== "" ?
                                    <Table.Row>
                                        <Table.Cell collapsing>
                                            Fault Explanation
                                    </Table.Cell>
                                        <Table.Cell className="table-data">{workorder.fault_Description}</Table.Cell>
                                    </Table.Row>
                                    : null}
                                {workorder.long_text !== "" ?
                                    <Table.Row>
                                        <Table.Cell collapsing>
                                            Work Order Long Text
                                    </Table.Cell>
                                        <Table.Cell className="table-data">{workorder.long_text}</Table.Cell>
                                    </Table.Row>
                                    : null}
                                {workorder.notification_text !== "" ?
                                    <Table.Row>
                                        <Table.Cell collapsing>
                                            Notification Long Text
                                    </Table.Cell>
                                        <Table.Cell className="table-data">{workorder.notification_text}</Table.Cell>
                                    </Table.Row>
                                    : null}
                                {workorder.pod_eod !== "" ?
                                    <Table.Row>
                                        <Table.Cell collapsing>
                                            PoD/EoD Notes
                                    </Table.Cell>
                                        <Table.Cell className="table-data">{workorder.pod_eod}</Table.Cell>
                                    </Table.Row>
                                    : null}
                            </Table.Body>
                        </Table>
                    </div>
                </Grid.Column>
            </>
        );
    }

    return (
        <div className="content-container home-container">
            <LexxBreadcrumbs/>
            <Grid centered className="work-order-grid">
                {
                    <Grid.Column width={14}>
                        <Grid centered>
                            <Grid.Column mobile={16} tablet={14} computer={12}>
                                <Header as="h1" dividing>
                                    {workorder.title}
                                    </Header>
                            </Grid.Column>
                            {getTable()}
                        </Grid>
                        <LexxSessionToolbar/>
                    </Grid.Column>
                }
            </Grid>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        troubleshootingData: state.troubleshootingData
    };
};

export default withRouter(connect(
    mapStateToProps
)(LexxWorkorder));
