import * as React from "react";

const List = (props) => (
    <>
        {(props.randomList && props.randomList.length > 0) && (
            <div dangerouslySetInnerHTML={{__html: props.randomList}}></div>
        )}
        {(props.sequentialList && props.sequentialList.length > 0) && (
            <div dangerouslySetInnerHTML={{__html: props.sequentialList}}></div>
        )}
    </>
);

export default List;