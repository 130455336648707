import React from "react";
import { Label, Table } from 'semantic-ui-react';
import zoomInImg from '../../assets/images/zoom_in-24px.svg';
import zoomOutImg from '../../assets/images/zoom_out-24px.svg';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";


/* Child container to show the schematics */
const LexxSchematicContainer = (props) => {


    const { img_src, partsbreakdown, tag } = props // extract the src of image
    // partsbreakdown is an obj will contain all the data for schematics


    /* This function returns the component for zoom in and out */
    const ZoomButton = (p) => {
        return (
            <button className="primary zoom-buttons" onClick={p.func}>
                <Label className='label-image-class' as='a'>
                    <img className='invert-color' src={p.img} alt="Action" />
                </Label>
            </button>
        )
    }


    return (
        <>
            {
                props.partsbreakdown ?
                    <>
                        <div>
                            <h2 className="schematic-title">{partsbreakdown.subCategory}</h2>
                            <div className="schematic-header-table">
                                <Table celled striped selectable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>
                                                Manual Name
                                        </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Chapter Name
                                        </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Function Name
                                        </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Drawing Number
                                        </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Cont. Sheet
                                        </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Sheet
                                        </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>
                                                {partsbreakdown.schematics_title}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {partsbreakdown.title}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {partsbreakdown.subCategory}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {partsbreakdown.drawing_number}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {partsbreakdown.cont_sheet}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {partsbreakdown.sheet}
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </> : null
            }
            <TransformWrapper
                defaultScale={1}
                defaultPositionX={200}
                defaultPositionY={100}
                pan={{ paddingSize: 0, velocityBaseTime: 0 }}
                scalePadding={{ disabled: true }}
            >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <React.Fragment>
                        <TransformComponent className="schematic-image-container">
                            {tag === "img" && <img src={img_src} alt="" className="media-display" />}
                            {tag === "video" && <video className="media-display" controls>
                                    <source
                                        className="media-display"
                                        src={img_src}
                                        alt=""
                                        type="video/mp4"
                                    />
                                    </video>}
                        </TransformComponent>
                        <div className="tools">
                            <ZoomButton img={zoomInImg} func={zoomIn} />
                            <ZoomButton img={zoomOutImg} func={zoomOut} />
                        </div>
                    </React.Fragment>
                )}
            </TransformWrapper>
        </>
    );
}

export default LexxSchematicContainer;