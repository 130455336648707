import React from 'react'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'

/* Component to render the Loader */

const LoaderGif = () => (

    <Segment className="loader-segment">
        <Dimmer active inverted>
            <Loader size='large'>Loading</Loader>
        </Dimmer>
    </Segment>
)

export default LoaderGif