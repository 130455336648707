import React, { useState, useEffect } from 'react';
import { Image, List } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { saveSearchResults, saveTroubleshootingData, saveTroubleshootingOption, saveTroubleshootingStep, setSearchType, saveVisitedTroubleshootingSteps, saveSearchQuery, saveSessionData, saveSessionUsers } from '../../redux/actions';
import { getNotifications, markNotificationRead } from '../apiCall';
import userDummy from '../../assets/images/user-dummy.png';

/* This is the component to show the notification messages */
function Notifications(props) {

    const [notifications, setNotifications] = useState([]); // notification array
    // const [showTaskDecline, setShowTaskDecline] = useState(false); // if task invite is rejected, show the modal for reason
    // const [chatModal, setChatModal] = useState([false, ""]); // chat modal to show or not
    // const [notificationChosen, setNotificationChosen] = useState(""); // notification choosen string

    /* To get the notification data from server */
    const getNotification = () => {
        getNotifications().then((response) => {
            setNotifications(response.data.data.notifications);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    useEffect(getNotification, []);


    /* Date to present in the UI */
    const getDate = (dateUTC) => {
        return dateUTC.getDate() + '/' + (dateUTC.getMonth() + 1) + '/' + dateUTC.getFullYear();
    }

    /* Time to present in the UI */
    const getTime = (dateUTC) => {
        if (dateUTC.getMinutes().toString().length === 1) {
            return dateUTC.getHours() + ":0" + dateUTC.getMinutes();
        } else {
            return dateUTC.getHours() + ":" + dateUTC.getMinutes();
        }
    }

    /* This is to format the message and show it in the UI based on the type of notification */
    const getMessage = (type) => {
        switch (type) {
            case 'TEAM_INVITE':
                return 'invited you to join their team';
            case 'Message':
                return 'sent you a message';
            case 'SESSION_INVITE':
                return 'invited you into a session';
            case 'TASK_ACCEPT':
                return 'accepted the task ';
            case 'TASK_DECLINE':
                return 'declined the task ';
            case 'TASK_STATUS_UPDATE':
                return 'has marked this ';
            case 'SESSION_SUMMARY':
                return 'has shared session data with you'
            case 'REPORT_COMPLETION':
                return 'has completed a report for a task.'
            case 'TEMP_CHAT_INVITE':
                    return 'wants to chat with you.'
            default:
                return 'assigned a task to you ';
        }
    }

    /* Get the height of the notification so that background can be updated for CSS */
    const getHeight = () => {
        return window.outerHeight > (notifications.length * 100) ? window.outerHeight + 'px' : (notifications.length * 100) + 'px';
    }

    /* Mark the notification as read */
    const markasRead = (notificationClicked) => {
        markNotificationRead("/" + notificationClicked.notificationId + "/read");
    }


    /* Function called when notification is clicked and then based on the type the variables are set */
    const notificationClickedFn = (notificationClicked) => {
        if (!notificationClicked.read || notificationClicked.type === 'SESSION_SUMMARY') {
            switch (notificationClicked.type) {
                case 'SESSION_SUMMARY':
                    markasRead(notificationClicked);
                    props.history.push("session/id=" + notificationClicked.sessionId);
                    break;
                default:
                    markasRead(notificationClicked);
                    getNotification();
                    break;
            }
        }
    }

    return (
        <div className="notificationsContainer" style={{ height: getHeight() }}>
            <List>
                {notifications.map((eachNotification, i) => {
                    return (
                        <List.Item key={i} className={eachNotification.read ? "read" : "unread"}>
                            {
                                eachNotification.source.avatar !== "" ?
                                <Image avatar src={eachNotification.source.avatar} onClick={() => props.history.push(`/userprofile/id=${eachNotification.source.id}`) }/> 
                                :
                                <Image avatar src={userDummy} onClick={() => props.history.push(`/userprofile/id=${eachNotification.source.id}`) }/> 
                            }
                           
                            <List.Content onClick={() => notificationClickedFn(eachNotification)}>
                                <List.Header>{eachNotification.source.name}
                                    <span> {getMessage(eachNotification.type)}
                                        {eachNotification.type.includes("TASK") ? <b>{eachNotification.taskTitle}</b> : null}
                                        {eachNotification.type === "TASK_STATUS_UPDATE" && eachNotification.toStatus !== undefined ? " as " + eachNotification.toStatus.charAt(0).toUpperCase() + eachNotification.toStatus.slice(1) + "." : null}
                                    </span>
                                </List.Header>
                                <List.Description>
                                    <span> {getDate(new Date(eachNotification.createTime))} </span>
                                    <span> at </span>
                                    <span> {getTime(new Date(eachNotification.createTime))} </span>
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    )
                })
                }
            </List>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userId: state.userInfo.userId
    };
};

export default withRouter(connect(mapStateToProps, {
    saveSearchResults,
    saveTroubleshootingData,
    saveTroubleshootingOption,
    saveTroubleshootingStep,
    setSearchType,
    saveVisitedTroubleshootingSteps,
    saveSearchQuery,
    saveSessionData,
    saveSessionUsers
})(Notifications));