import * as React from "react";
import { List as SList } from "semantic-ui-react";


const List = (props) => (
    <>
        {(props.children && props.children.length > 0) && (
            props.list_type === "ordered"  ? <SList ordered >{props.children}</SList> :
            props.list_type === "bulleted" ? <SList bulleted>{props.children}</SList> :
                                             <SList         >{props.children}</SList>
        )}
    </>
);

export default List;
