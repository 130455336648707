import * as React from "react";

const Para = (props) => (
    <>
        {(props.text && props.text.length > 0) && (
            <div dangerouslySetInnerHTML={{__html: props.text}}></div>
        )}
    </>
);

export default Para;
