import React, { useState } from "react";
import { Container } from "semantic-ui-react";
import axios from "axios";
import { connect } from "react-redux";
import {
  saveSearchResults,
  saveTroubleshootingOption,
  saveVisitedTroubleshootingSteps,
  saveSearchQuery,
  searchClicked,
  saveTroubleshootingData,
  saveNumFound,
  setFiltervalues,
  addfilter,
  setActivePage
} from "../../redux/actions";
import LexxSearchBar from "../LexxSearchBar/lexxsearchbar.jsx";
import LexxBreadcrumbs from "../LexxBreadcrumbs/LexxBreadcrumbs";
import LexxIconHeading from "../global/LexxIconHeading";
import Loader from "../global/Loader";
import { search } from "../apiCall";
import KeycloakUserService from "../login/KeyCloakService";
import Filter from "../Filter/Filter";
/* This is the SEARCH page where users search for fault code or WI */
const TroubleshootingHome = (props) => {
  const [isloading, setIsLoading] = useState(false); // to show loader when the user searches
  const [groupId, setgroupId] = useState(KeycloakUserService.getGroups());
  /* Function called when the user searches for anything 
     @query - input from the user 
     */
  function gettextforsearchbar(){
    if(props.isTroubleshooting){
      if(localStorage.getItem('projectGroup') === '/EDPRG'){
        return(
          <p>You can search for information within Troubleshooting or Work Order.</p>
        )
      }
      else{
        return null
      }

    }
    else{
      if(localStorage.getItem('projectGroup') === '/EDPRG'){
        return(
          <p>You can search for information within Manual,Schematics,Datasheet, Work Instruction or Operation Description.</p>
        )

      }
      else{
        return(
          <p>You can search for information within Work Instructions or Schematics. </p>
        )

      }


    }
  }
  const triggerSearch = (query) => {
    setIsLoading(true);
    axios.defaults.params = {};
    axios.defaults.params["username"] = props.userInfo.username;
    search(query, props.isTroubleshooting, [], 0, 10)
      .then((res) => {
        //sent to the backend
        console.log(res);
        // Clear docs with no data for lonestar only
        if (
          localStorage.getItem("projectGroup") === "/EDPRL" ||
          localStorage.getItem("projectGroup") === "/EDPRL_dev"
        ) {
          for (let i = 0; i < res.data.docs.length; i++) {
            if (JSON.parse(res.data.docs[i].data[0]).length == 0) {
              delete res.data.docs[i];
            }
          }
        }
        // Save and reset redux
        props.searchClicked(true);
        props.saveSearchResults(res.data.docs);
        if(localStorage.getItem('projectGroup') === '/EDPRG'){
          props.isTroubleshooting ? props.setFiltervalues(res.data.facets ? JSON.stringify(res.data.facets[1].values) : [])
          : props.setFiltervalues(
              res.data.facets ? JSON.stringify(res.data.facets) : []
            );
        }
       
        props.saveNumFound(res.data.numFound);
        props.saveTroubleshootingOption(null);
        props.saveTroubleshootingData(null);
        props.saveVisitedTroubleshootingSteps([]);
        props.saveSearchQuery(query);
        props.addfilter(null);
        props.setActivePage(1);
        setIsLoading(false);
        if (
          localStorage.getItem("projectGroup") === "/EDPRG" &&
          !props.isTroubleshooting
        ) {
          console.log("..................");
          props.history.push("/lookupinfo/xviewer-issues");
        } else if (!props.isTroubleshooting) {
          props.history.push("/lookupinfo/issues");
        } else {
          props.history.push("/troubleshooting/issues");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
        if (err.response.status === 403) {
          KeycloakUserService.doLogout();
        }
      });
  };

  if (isloading) {
    return <Loader />;
  } else {
    return (
      <div className="content-container">
        <LexxBreadcrumbs />
        <Container>
          {props.isTroubleshooting ? (
            <LexxIconHeading text="Can you please describe the fault?" />
          ) : (
            <LexxIconHeading text="Can you please describe what information you want to look up?" />
          )}

          <div className="marginL3">
            <p className="section-header-text">Your response</p>
            <LexxSearchBar
              className="search-input-box"
              act={(query) => {
                triggerSearch(query);
              }}
            />
           {gettextforsearchbar()}    
          </div>
        </Container>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    searchResults: state.searchResults, //search results from the redux
    isTroubleshooting: state.isTroubleshooting, //if it is troubleshooting or not
    userInfo: state.userInfo, //user data
  };
};

export default connect(mapStateToProps, {
  saveSearchResults,
  saveTroubleshootingOption,
  saveVisitedTroubleshootingSteps,
  saveSearchQuery,
  searchClicked,
  saveNumFound,
  saveTroubleshootingData,
  setFiltervalues,
  addfilter,
  setActivePage
})(TroubleshootingHome);
