import React from 'react';
import LexxSchematicContainer from '../LexxSchematicContainer/LexxSchematicContainer';
import { Image } from 'semantic-ui-react';

/* This component is to render the images whether it is:
    1. Troubleshooting
    2. Work Instruction
 */
const LexxImage = (props) => {

    /* Function is to map images, if troubleshooting then call schematic component
        otherwise, its native image component.
        @obj - data which contains images
    */
    const mapTag = (obj) => {
        let images = obj.tag_data.map((img, key) => {
            if(props.troubleshooting) {
                return (
                    <LexxSchematicContainer img_src={img} key={key} tag={obj.tag}/>
                )
            }else{
                return (
                    <Image src={img} key={key} centered />
                )
            }
        });
        return (
            <React.Fragment>
                {images}
            </React.Fragment>
        );
    }

    return (
        <>
            {mapTag(props.imageData)}
        </>
    );
};

export default LexxImage;