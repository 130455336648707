import * as React from "react";

const List = (props) => {
  return (
    <>
      {((props.text && props.text.length > 0) ||
        (props.table && props.table.length > 0)) && (
        <div
          className="procedure-table"
          style={{overflowX:'scroll'}}
          dangerouslySetInnerHTML={{
            __html: props.text,
          }}
        ></div>
      )}
    </>
  );
};

export default List;