import * as React from "react";
import { Icon, Message } from "semantic-ui-react";


const Caution = (props) => (
    <Message warning icon>
        <Icon name="exclamation circle"/>
        <Message.Content>
            <Message.Header>Caution</Message.Header>
            {(props.children && props.children.length > 0) && props.children}
        </Message.Content>
    </Message>
);

export default Caution;
