// import React, { useState } from 'react';
import React from "react";
import { useState } from "react";
import { Card, Image } from "semantic-ui-react";
import Technician from "../../assets/images/technician.png";
import LexxButton from "../LexxButton/lexxbutton.jsx";
// import LexxAskHelp from './LexxAskHelp';
// import LexxAskHelpChat from "./LexxAskHelpChat";
import { setSearchType } from "../../redux/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useEffect } from "react";

/* This component is for Dashboard buttons - Troubleshooting, Lookup and Help from Technician */
function LexxDashboardCard(props) {
  const [featureFlag, setFeatureFlag] = useState(props.featureFlag);

  useEffect(() => {
    setFeatureFlag(props.featureFlag);
  }, [featureFlag, props.featureFlag]);
  // Most of it is commented as xTeamTrial components are for full version

  // const [technicianModal, setTechnicianModal] = useState(false);
  // const [chatModal, setChatModal] = useState(false); // show the chat Modal when Technician clicks on the btn
  // const [userId, setUserId] = useState("");  // show t

  /* This function is called when the chat is loaded */

  // const setUpChat = (isChatModal, userInfo) => {
  //     setUserId(userInfo);
  //     setChatModal(isChatModal); // show the modal
  // }
  return (
    <Card className="dashboard-card">
      <div className="dashboard-card-content">
        <div className="dashboard-card-image">
          <Image src={Technician} className="dashboard-card-image-holder" />
        </div>
        <div className="dashboard-card-messages">
          <table>
            <thead>
              <tr>
                <td>
                  <h3>Hi, LexX here!</h3>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="label">What would you like to do?</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {console.log("featureFlag", featureFlag)}
      <div className="dashboard-card-buttons">
        <LexxButton
          disabled={!featureFlag?.["fix-a-problem"]}
          primary={true}
          text="I want to fix a problem"
          act={() => {
            props.setSearchType(true);
            props.history.push("/troubleshooting"); //troubleshooting page
          }}
        />
        <LexxButton
          primary={false}
          disabled={!featureFlag?.["look-up"]}
          text="I want to lookup information"
          act={() => {
            props.setSearchType(false);
            props.history.push("/lookupinfo"); //lookupinfo page
          }}
        />
        <LexxButton
          disabled={!featureFlag?.["help-from-someone"]}
          text="I want help from someone"
          // act={() => {
          //     setTechnicianModal(true);
          // }}
        />
      </div>
      {/* {
                technicianModal ?
                    <LexxAskHelp open={technicianModal} close={setTechnicianModal} setUpChat={setUpChat} /> : null
            }
            {
                chatModal ?
                    <LexxAskHelpChat open={chatModal} close={setChatModal} toUser={userId} /> : null
            } */}
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    isTroubleshooting: state.isTroubleshooting,
    featureFlag: state.featureFlag,
  };
};

export default withRouter(
  connect(mapStateToProps, { setSearchType })(LexxDashboardCard)
);
