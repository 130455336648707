import React from 'react';
import { Table } from 'semantic-ui-react';
import LexxPara from './LexxPara';

/* Component to Render Table for Procedure */
const LexxFiTable = (props) => {

    /* Function to map tag data to Table cell
        @subType: is the tag data 
        @isHeader: boolean if we are mapping header or body
    */
    const mapTable = (subType, isHeader) => {
        let tableData = [];
        subType.tag_data.forEach((tr) => {
            let cellData = [];
            tr.tag_data.forEach((td) => {
                td.tag_data.forEach((para) => {
                    if (para.tag === 'p') {
                        isHeader ?
                            cellData.push(
                                <Table.HeaderCell
                                    className='table-data'
                                    key={Math.floor((Math.random() * 1000) + 1)}
                                >
                                    <LexxPara paraData={para} troubleshooting={props.troubleshooting} />
                                </Table.HeaderCell>
                            )
                        :
                            cellData.push(
                                <Table.Cell
                                    className='table-data'
                                    key={Math.floor((Math.random() * 1000) + 1)}
                                >
                                    <LexxPara paraData={para} troubleshooting={props.troubleshooting} />
                                </Table.Cell>
                            );
                    }
                });
            });
            tableData.push(<Table.Row key={Math.floor((Math.random() * 1000) + 1)}>{cellData}</Table.Row>);
        });
        return tableData;
    };

    /* Function to map table data on a higher level(title, header, body)
        @obj: data we get under table tag for Work instruction
    */
    const mapTag = (obj) => {
        let bodyData, headerData, titleData = [];
        obj.tag_data.forEach((subType) => {
            if (subType.tag === 'title') {
                titleData.push(
                    <p key={Math.random()} className="text-align-center">
                        {subType.tag_data[0]}
                    </p>
                );
            } else if (subType.tag === 'thead') {
                headerData = mapTable(subType, true);
            } else {
                bodyData = mapTable(subType, false);
            }
        });
        return [titleData, headerData, bodyData];
    };

    return (
        <>
            {mapTag(props.tableData)[0]}
            <Table celled>
                <Table.Header>
                    {mapTag(props.tableData)[1]}
                </Table.Header>
                <Table.Body>
                    {mapTag(props.tableData)[2]}
                </Table.Body>
            </Table>
        </>
    );
};

export default LexxFiTable;