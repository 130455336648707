import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Sidebar } from "semantic-ui-react";
import { changeSidebarVisibility } from "../redux/actions";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import Header from "./menu/Header";
import SidebarComp from "./menu/SidebarComp";
import TroubleshootingHome from "./troubleshooting/TroubleshootingHome";
import LexxFeedback from "./LexxFeedback/LexxFeedback";
import TroubleshootingAction from "./troubleshooting/TroubleshootingAction";
import ThankYouHome from "./thankyou/ThankYouHome";
import Procedure from "./procedure/Procedure";
import LexxSearchResults from "./LexxSearchResults/LexxSearchResults";
import LexxTroubleshootingOptions from "./LexxTroubleshootingOptions/LexxTroubleshootingOptions";
import LexxWorkorder from "./LexxWorkorder/LexxWorkorder";
import LexxSchematicView from "./LexxSchematicContainer/LexxSchematicView";
import LexxDashboard from "./LexxDashboard/LexxDashboard";
import {
  loginAction,
  saveActiveTime,
  setFeatureflag,
} from "../redux/actions/index";
import LexxIdlePopup from "../components/LexxIdlePopup/LexxIdlePopup";
import IdleTimer from "react-idle-timer";
import LexxTeamResult from "./LexxTeam/LexxTeamResult";
import UserProfile from "./profile/UserProfile";
import Notifications from "./menu/Notifications";
import EditProfile from "../components/profile/EditUserProfile";
import Sessions from "./LexxSession/LexxAllSessions";
import LexxSessionSummary from "./LexxSession/LexxSessionSummary";
import { logData, getFeatureFlag } from "./apiCall";
import KeycloakUserService from "../components/login/KeyCloakService";
import AddSolutionsFaultDetails from "./AddSolution/AddSolutionsFaultDetails";
import AddSolutionsSteps from "./AddSolution/AddSolutionsSteps";
import NewStep from "./AddSolution/NewStep";
import NewSolutions from "./AddSolution/NewSolutions";
import SolutionSummary from "./AddSolution/SolutionSummary";
import LexxSearchResultsXviewer from "./LexxSearchResults/LexxSearchResultsXviewer.jsx";
import NewProcedure from "./procedure/NewProcedure.jsx";
import WorkOrderDisplay from "./LexxSearchResults/WorkOrderDisplay";

const App = (props) => {
  const [showModal, setShowModal] = useState(false);
  const timeout = 1000 * 60 * 30; // 30mins
  const [timer, setTimer] = useState(null);
  const [isTimedOut, setIsTimeOut] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("projectGroup")) {
      localStorage.setItem("projectGroup", KeycloakUserService.getGroups()[0]);
    }
    // if(!localStorage.getItem("featureList") && localStorage.getItem("app_token")) {
    getFeatureFlag().then((res) => {
      let featureObj = {};
      for (let i = 0; i < res.data[0].features.length; i++) {
        featureObj[res.data[0].features[i].featureId] =
          res.data[0].features[i].state === "enabled" ? true : false;
      }
      props.setFeatureflag(featureObj);
    });
    // }
  }, []);

  const onActive = () => {
    setIsTimeOut(false);
  };

  const onIdle = (e) => {
    console.log("onIdle");
    if (!isTimedOut) {
      setShowModal(true);
      setTimer(null);
      setIsTimeOut(true);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleLogout = () => {
    const data = {
      params: {
        username: props.userInfo.username,
        sessionTime: timer.getElapsedTime() / 1000,
      },
    };
    logData(data);
    setShowModal(false);
    localStorage.clear();
    KeycloakUserService.doLogout();
  };

  const onAction = (e) => {
    setIsTimeOut(false);
  };

  if (!KeycloakUserService.isLoggedIn()) {
    return KeycloakUserService.doLogin();
  } else {
    return (
      <div>
        <IdleTimer
          ref={(ref) => {
            setTimer(ref);
            props.saveActiveTime(ref == null ? null : ref.getElapsedTime());
          }}
          element={document}
          onActive={onActive}
          onIdle={onIdle}
          onAction={onAction}
          timeout={timeout}
        />
        <Sidebar.Pushable>
          <SidebarComp />
          <Sidebar.Pusher dimmed={props.sidebarVisible}>
            <Header />
            <Switch>
              <Route exact path="/" component={LexxDashboard} />
              <Route
                exact
                path="/troubleshooting"
                component={TroubleshootingHome}
              />
              <Route exact path="/lookupinfo" component={TroubleshootingHome} />
              <Route exact path="/feedback/" component={LexxFeedback} />
              <Route
                exact
                path="/troubleshooting/issues/options/action"
                component={TroubleshootingAction}
              />
              <Route
                exact
                path="/troubleshooting/issues"
                component={LexxSearchResults}
              />
                            <Route
                exact
                path="/lookupinfo/issues"
                component={LexxSearchResults}
              />
              <Route
                exact
                path="/lookupinfo/xviewer-issues"
                component={LexxSearchResultsXviewer}
              />
              <Route exact path="/newprocedure" component={NewProcedure} />
              <Route
                exact
                path="/troubleshooting/issues/options/"
                component={LexxTroubleshootingOptions}
              />
              <Route
                exact
                path="/troubleshooting/issues/workorder/"
                component={LexxWorkorder}
              />
              <Route
                exact
                path="/workorder-data"
                component={WorkOrderDisplay}
              />
              <Route exact path="/thankyou" component={ThankYouHome} />
              <Route
                exact
                path="/schematic/id=:id"
                component={LexxSchematicView}
              />
              <Route exact path="/procedure/id=:id" component={Procedure} />
              <Route exact path="/myteam/" component={LexxTeamResult} />
              <Route
                exact
                path="/userProfile/id=:id"
                render={() => <UserProfile otherProfile={true} />}
              />
              <Route path="/editProfile" component={EditProfile} />
              <Route
                path="/userProfile"
                render={() => <UserProfile otherProfile={false} />}
              />
              <Route
                exact
                path="/allNotifications/"
                component={Notifications}
              />
              <Route exact path="/allSessions" component={Sessions} />
              <Route
                exact
                path="/session/id=:id"
                component={LexxSessionSummary}
              />
              <Route
                exact
                path="/addsolutionsfaultdetails"
                component={AddSolutionsFaultDetails}
              />
              <Route
                exact
                path="/addsolutionssteps"
                component={AddSolutionsSteps}
              />
              <Route
                exact
                path="/solutionsummary"
                component={SolutionSummary}
              />
              <Route exact path="/newstep" component={NewStep} />
              <Route exact path="/newsolutions" component={NewSolutions} />
              <Route exact path="*" render={() => <Redirect to="/" />} />
            </Switch>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
        {showModal ? (
          <LexxIdlePopup
            showModal={showModal}
            handleClose={handleClose}
            handleLogout={handleLogout}
          />
        ) : null}
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    sidebarVisible: state.sidebarVisible,
    userInfo: state.userInfo,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    changeSidebarVisibility,
    loginAction,
    saveActiveTime,
    setFeatureflag,
  })(App)
);
