import * as React from "react";
import { Image as SImage } from "semantic-ui-react";

const Image = (props) => (
  <>
    <SImage src={`${props.src}.png`} centered />
    {props.children && props.children.length > 0 && props.children}
  </>
);

export default Image;
