// import React, { useState, useEffect } from 'react'
import React, { useEffect } from 'react'
import { Image, Menu, Dropdown } from 'semantic-ui-react'
import chatImg from '../../assets/images/chat.png';
import learningImg from '../../assets/images/learning.png';
import addImg from '../../assets/images/addPerson.png';
import dotsImg from '../../assets/images/dots.png';
import nextImg from '../../assets/images/next.png';
import faultFixedImg from '../../assets/images/fault_fixed.png';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { faultFixed } from "../apiCall";

// let socket;
/* This component is to display the bottom menu for session */
function LexxSessionToolbar(props) {

    const faultText = props.isTroubleshooting ? "Fault is Fixed" : "End Look Up";
    const nextSvgImg  = <svg xmlns="http://www.w3.org/2000/svg" viewBox= "5 5 16 16" className='svgClass'>
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M6 18l8.5-6L6 6v12zM16 6v12h2V6h-2z"/>
                    </svg>
    const faultFixSvgImg = <svg xmlns="http://www.w3.org/2000/svg" viewBox= "5 5 16 16" className='svgClass'>
        <g><rect fill="none" height="24" width="24"/></g>
        <g><g><rect height="2" width="11" x="3" y="10"/>
        <rect height="2" width="11" x="3" y="6"/><rect height="2" width="7" x="3" y="14"/>
        <polygon points="20.59,11.93 16.34,16.17 14.22,14.05 12.81,15.46 16.34,19 22,13.34"/>
        </g></g></svg>
    let extraItems = [];

    /* Fixed items at bottom of the page */
    const items = [
        {
            text: 'Add learnings',
            key: 'learning',
            icon: learningImg,
            allowedToSessionShare: true,
            disable: true
        },
        {
            key: props.isTroubleshooting ? faultText : 'Session Chat',
            text: props.isTroubleshooting ? faultText : 'Session Chat',
            value: props.isTroubleshooting ? faultText : 'Session Chat',
            icon: props.isTroubleshooting ? faultFixedImg : chatImg,
            allowedToSessionShare: false,
            disable: false
        },
    ]

    useEffect(()=>{
        getExtraItems();
    },[]);

    /* These extra Items are shown through 3 dots */
    const getExtraItems = () => {
        if(props.isTroubleshooting){
            extraItems.push({
                key: props.isTroubleshooting ? 'Invite Colleague' : 'Next Step',
                text: props.isTroubleshooting ? 'Invite Colleague' : 'Next Step',
                value: props.isTroubleshooting ? 'Invite Colleague' : 'Next Step',
                image: props.isTroubleshooting ? { avatar: true, src: addImg } : nextImg
            });

        }
        extraItems.push({
            key: props.isTroubleshooting ? 'Session Chat' : faultText,
            text: props.isTroubleshooting ? 'Session Chat' : faultText,
            value: props.isTroubleshooting ? 'Session Chat' : faultText,
            image: props.isTroubleshooting ? { avatar: true, src: chatImg } : faultFixedImg
        });
        return extraItems;
    };

    /* If the extra items are clicked from 3 dots 
        redirect user based on the item clicked
    */
    const extraItemClicked = (value) => {
        if (value === 'Next Step' && props.isTroubleshooting) {
            props.history.push("/troubleshooting/issues/options/");
        } else if(value === 'Next Step'){
            props.history.goBack();
        }
        else if(value === faultText) {
            faultFixed(props.sessionID);
            props.history.push(`/feedback/`);
        }
    }

    /* If the fixed items are clicked, show modal for invite user or chat modal 
        @key - passed from UI from the option selected
    */
    const optionSelected = (value) => {
        if (value === 'Next Step' && props.isTroubleshooting) {
            props.history.push("/troubleshooting/issues/options/");
        } else if(value === 'Next Step'){
            props.history.goBack();
        }
        else if(value === faultText) {
            faultFixed(props.sessionID);
            props.history.push(`/feedback/`);
        }
    }

    // /* Show or hide the chat modal */
    // const chatWindow = (isOpen) => {
    //     setSessionChat(isOpen);
    // }

    // /* Show or hide the invite technician modal */
    // const inviteMember = (isOpen) => {
    //     setInviteModal(isOpen);
    // }

    return (
        <>
            <Menu className="menu-height session-bar">
            {props.isOwner ?
                    <div className="item footer-menu">
                        <div className='grayed-out-code'>
                        <Image src={dotsImg} className='footer-toolbar-image' />
                        <Dropdown item text='More' disabled>
                            <Dropdown.Menu>
                                {getExtraItems().map((option) => (
                                    <Dropdown.Item key={option.value} {...option} onClick={() => extraItemClicked(option.value)} />
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                        </div>
                    </div>
                    : null}
                {
                    items.map((val) => {
                        if (props.isOwner) {
                            return (
                                <Menu.Item
                                    key={val.key}
                                    name={val.text}
                                    className="footer-menu"
                                    onClick={() => optionSelected(val.key)}
                                    disabled = {val.disable}
                                >
                                    <>
                                    <div className={val.disable === true ?"grayed-out-code":""}>
                                    { props.isTroubleshooting && val.key === "Next Step" ? nextSvgImg : 
                                            val.key === faultText ? faultFixSvgImg :
                                            <Image src={val.icon} className='footer-toolbar-image' />
                                        }
                                        <p className='footer-toolbar-text-selected'>{val.text}
                                            {/* {
                                                val.key === 'chat'&& unread > 0 ?
                                                    <span className="notification-number session-chat owner"> {unread} </span>
                                                    : null
                                            } */}
                                        </p>
                                        </div>
                                    </>
                                </Menu.Item>
                            )
                        } else {
                            if (val.allowedToSessionShare && !props.isShared) {
                                return (
                                    <Menu.Item
                                        key={val.key}
                                        name={val.text}
                                        className="footer-menu shared"
                                        onClick={() => optionSelected(val.key)}
                                        disabled = {val.disable}
                                    >
                                        <>
                                        <div className={val.disable === true ?"grayed-out-code":""}>                                        
                                            <Image src={val.icon} className='footer-toolbar-image' />
                                            <p className='footer-toolbar-text-selected'>{val.text}
                                                {/* {
                                                    val.key === 'chat' && unread > 0 ?
                                                        <span className="notification-number session-chat"> {unread} </span>
                                                        : null
                                                } */}
                                            </p>
                                        </div>
                                        </>
                                    </Menu.Item>
                                )
                            }
                            return null;
                        }
                    })
                }
            </Menu>
            {/* {
                inviteModal ? <LexxInviteToSession open={inviteModal} inviteMember={inviteMember} /> : null

            }
            {
                sessionChat ? <LexxChat open={sessionChat} chatWindow={chatWindow} socket={socket} usersInChat={props.sessionUsers} /> : null

            } */}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isTroubleshooting: state.isTroubleshooting,
        isOwner: state.sessionData.isOwner,
        isShared: state.sessionData.isShared,
        sessionID: state.sessionData.sessionID,
        username: state.userInfo.userId,
        sessionUsers: state.sessionUsers
    };
};

export default withRouter(connect(mapStateToProps)(LexxSessionToolbar));