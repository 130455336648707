/* eslint-disable no-magic-numbers */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Accordion, Icon } from "semantic-ui-react";
import OptionSelector from "./OptionSelector/OptionSelector";
const Sidebar = (highlights) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [clickedToc, setClickedToc] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const docDetail = useSelector((state) => state.docDetail);
  const [isOptionSelectVisible, setIsOptionSelectVisible] = useState(false);
  let id = docDetail.meta ? docDetail.meta[0].id.replace("/meta#", "") : "";
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
    docDetail.meta && updateHash({ id: id });
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const updateHash = (highlight) => {
    setClickedToc(highlight.id);
    document.location.hash = `highlight-${highlight.id}`;
  };
  const handleShowSelector = () => {
    setIsOptionSelectVisible(!isOptionSelectVisible);
  };
  const handleOptionSelect = (highlight) => {
    updateHash(highlight);
    setIsOptionSelectVisible(false);
  };

  return (
    <div className="pdf-container-body">
      {isMobile && (
        <div className="hamburger-menu-icon">
          <Icon name="bars" onClick={handleShowSelector} />
        </div>
      )}
      <div className="sidebar">
        <div className="description" onClick={handleShowSelector}>
          {isOptionSelectVisible && isMobile && (
            <OptionSelector
              contents={highlights.tableContents}
              updateHash={updateHash}
              handleOptionSelect={handleOptionSelect}
              clickedToc={clickedToc}
              handleShowSelector={handleShowSelector}
            />
          )}
        </div>
        <div>
          <div className="description" />
          {!isMobile && (
            <Accordion>
              {/* <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={handleClick}
        >
          <div style={{ fontSize: "18px", fontWeight: "bold" }}>
            <Icon name="dropdown" />
            Table of Content{" "}
          </div>
        </Accordion.Title> */}
              <Accordion.Content active={true}>
                <ul className="sidebar__highlights">
                  {highlights.tableContents
                    .filter(
                      (highlight) =>
                        highlight.content.text !== "Summary from search display"
                    )
                    .map((highlight, index) => (
                      <div>
                        {highlight.isOutLine === true && (
                          <div
                            className="tableOfContents"
                            id={highlight.id}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingBottom: "5px",
                              marginLeft: "10px",
                              paddingLeft: "10px",
                              marginRight: "10px",
                              paddingBottom: "15px",
                              backgroundColor:
                                clickedToc === highlight.id ? "#EEF5FC" : "",
                              borderRadius: "5px",
                            }}
                          >
                            <div>
                              <li
                                key={index}
                                className="sidebar__highlight"
                                onClick={() => {
                                  updateHash(highlight);
                                }}
                              >
                                <div>
                                  <strong>{highlight.comment.text}</strong>
                                  {highlight.content.text ? (
                                    <blockquote
                                      style={{
                                        marginTop: "0.5rem",
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        lineHeight: "18px",
                                        fontFamily: "Inter",
                                        color:
                                          clickedToc === highlight.id
                                            ? "#034E91"
                                            : "",
                                      }}
                                    >
                                      {highlight.content.text}
                                    </blockquote>
                                  ) : null}
                                  {highlight.content.image ? (
                                    <div
                                      className="highlight__image"
                                      style={{ marginTop: "0.5rem" }}
                                    >
                                      <img
                                        src={highlight.content.image}
                                        alt={"Screenshot"}
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              </li>
                            </div>

                            <div>
                              <Icon
                                name={"chevron right"}
                                style={{
                                  height: "20px",
                                  marginRight: "15px",
                                  marginTop: "15px",
                                  cursor: "pointer",
                                  fontWeight: "light",
                                  color:
                                    clickedToc === highlight.id
                                      ? "#034E91"
                                      : "",
                                  fontSize: "10px",
                                }}
                                onClick={() => {
                                  updateHash(highlight);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                </ul>
              </Accordion.Content>
            </Accordion>
          )}
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
