import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Input, Icon, Header, Label } from "semantic-ui-react";
import {
  saveSessionData,
  saveSearchResults,
  saveTroubleshootingData,
  saveTroubleshootingOption,
  saveTroubleshootingStep,
  setSearchType,
  saveVisitedTroubleshootingSteps,
  saveSearchQuery,
  saveSessionUsers,
  setFiltervalues,
  saveNumFound,
  addfilter,
  setActivePage
} from "../../redux/actions";

import { getAllSessions, getSingleSession } from "../apiCall";
import userDummy from "../../assets/images/user-dummy.png";

/* Component to show list of the sessions */
function LexxAllSessions(props) {
  const [search, setSearch] = useState(""); //search query for sessions
  const [sessions, setSessions] = useState(""); // session list which changes when we search
  const [stableSessions, setStableSessions] = useState(""); // stable session list

  /* This function get all the sessions from the server
        and sets it in the variable sessions and stableSessions
    */
  const getSessions = () => {
    getAllSessions()
      .then((response) => {
        setStableSessions(response.data.data);
        setSessions(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(getSessions, []);

  /* Function to convert UTC date to readable date */
  const getDate = (dateUTC) => {
    return (
      dateUTC.getDate() +
      "/" +
      (dateUTC.getMonth() + 1) +
      "/" +
      dateUTC.getFullYear()
    );
  };

  /* Function to convert UTC date to readable time */
  const getTime = (dateUTC) => {
    if (dateUTC.getMinutes().toString().length === 1) {
      return dateUTC.getHours() + ":0" + dateUTC.getMinutes();
    } else {
      return dateUTC.getHours() + ":" + dateUTC.getMinutes();
    }
  };

  /* Function called when the user searches for a session
        @event - event returned from UI for search query
        filter the stable Sessions and search for matched sessions
    */
  const changeSearch = (event) => {
    setSearch(event.currentTarget.value);
    const filteredData = stableSessions.filter((item) => {
      return Object.keys(item).some((eachKey) => {
        if (eachKey === "searchQuery") {
          return item[eachKey]
            .toLowerCase()
            .includes(event.currentTarget.value.toLowerCase());
        } else {
          return null;
        }
      });
    });
    setSessions(filteredData);
  };

  /* Function to get color based on status and return class for css
        @status - the status of the session (paused, inprogress or completed)
    */
  const getColor = (status) => {
    switch (status.toLowerCase()) {
      case "paused":
        return "task-status yellow";
      case "inprogress":
        return "task-status green";
      default:
        return "task-status green";
    }
  };

  /* This function is called when a session needs to be loaded
        @sessionSate - the complete session data needed to load the session
    */
  const loadSession = (sessionState) => {
    let userDetails = [];
    props.saveSessionData({
      sessionID: sessionState.sessionId,
      isOwner: props.userId === sessionState.owner.userId ? true : false,
    });
    props.saveSearchResults(sessionState.searchResults);
    props.saveTroubleshootingData(sessionState.troubleshootingData);
    props.saveTroubleshootingOption(sessionState.troubleshootingOption);
    props.saveTroubleshootingStep(sessionState.troubleshootingStep);
    props.setSearchType(sessionState.isTroubleshooting);
    props.saveVisitedTroubleshootingSteps(
      sessionState.visitedTroubleshootingSteps
    );
    props.saveSearchQuery(sessionState.searchQuery);
    userDetails.push(sessionState.owner, ...sessionState.guests);
    props.saveSessionUsers(userDetails);
    props.setFiltervalues(sessionState.filterlist?sessionState.filterlist:JSON.stringify([]));
    props.saveNumFound(sessionState.numfound);
    props.addfilter(sessionState.appliedfilter?sessionState.appliedfilter:JSON.stringify([]));
    props.setActivePage(sessionState.activepage?sessionState.activepage:1);
    props.history.push(sessionState.routerPath);
  };

  /* This function is called when a session is clicked 
            @sessionID - session ID
        */
  const extractSession = (sessionID) => {
    getSingleSession(sessionID)
      .then((response) => {
        loadSession(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="my-sessions">
      <Header as="h2">My Sessions</Header>
      <div className="searchTeam">
        <Input
          icon={<Icon name="search" color="black" />}
          iconPosition="left"
          placeholder="Search"
          value={search}
          onChange={changeSearch}
        />
      </div>
      {sessions.length > 0 &&
        sessions
          .map((item, key) => {
            return (
              <div
                key={key}
                className="each-session"
                onClick={() => extractSession(item.sessionId)}
              >
                <div className="time">
                  {" "}
                  {getDate(new Date(item.createTime)) +
                    " " +
                    getTime(new Date(item.createTime))}{" "}
                </div>
                <div className="query"> {item.searchQuery} </div>
                {!item.troubleshootingData ? (
                  " "
                ) : (
                  <div className="task-details">
                    <div className="task-name">
                      {" "}
                      {item.troubleshootingData.info_type ===
                      "Examinations, tests & checks"
                        ? "Work Instruction"
                        : item.troubleshootingData.info_type}{" "}
                      • {item.troubleshootingData.title}{" "}
                    </div>
                  </div>
                )}
                <div className="info">
                  <Label
                    circular
                    className="collaborators"
                    as="a"
                    image={item.owner.avatar}
                  />
                  {item.guests.map((collab_id, k) => {
                    if (collab_id.avatar !== "") {
                      return (
                        <Label
                          key={k}
                          circular
                          className="collaborators"
                          as="a"
                          image={collab_id.avatar}
                        />
                      );
                    } else {
                      return (
                        <Label
                          key={k}
                          circular
                          className="collaborators"
                          as="a"
                          image={userDummy}
                        />
                      );
                    }
                  })}
                  {/* {checkMessages(item) > 0 ?
                                <span className="notification-number allSession">{checkMessages(item)}</span> : null
                            } */}
                  {!item.task ? (
                    " "
                  ) : (
                    <div className={getColor(item.task.status) + " marT10"}>
                      {" "}
                      {item.completeTime !== undefined
                        ? "Completed"
                        : "In Progress"}{" "}
                    </div>
                  )}
                  {item.owner.id !== props.userId ? (
                    <div className="shared-me"> Shared with me </div>
                  ) : null}
                </div>
              </div>
            );
          })
          .reverse()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userId: state.userInfo.userId,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    saveSearchResults,
    saveTroubleshootingData,
    saveTroubleshootingOption,
    saveTroubleshootingStep,
    setSearchType,
    saveVisitedTroubleshootingSteps,
    saveSearchQuery,
    saveSessionData,
    saveSessionUsers,
    setFiltervalues,
    saveNumFound,
    addfilter,
    setActivePage
  })(LexxAllSessions)
);
