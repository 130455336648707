import * as React from "react";
import Caution from "./types/Caution";
import Image from "./types/Image";
import Figure from "./types/Figure";
import List from "./types/List";
import ListItem from "./types/ListItem";
import Note from "./types/Note";
import Para from "./types/Para";
import Reference from "./types/Reference";
import Root from "./types/Root";
import Title from "./types/Title";
import Unknown from "./types/Unknown";
import Warning from "./types/Warning";
import Table from "./types/Table";
import RandomList from "./types/RandomList";

class S1000D extends React.Component {
  constructor(props) {
    super(props);
    this.renderS1000D = this.renderS1000D.bind(this);
    this.getTypeMapping = this.getTypeMapping.bind(this);
  }

  getTypeMapping(type) {
    switch (type) {
      case "caution":
        return Caution;
      case "image":
        return Image;
      case "figure":
        return Figure;
      case "list":
        return List;
      case "list_item":
        return ListItem;
      case "note":
        return Note;
      case "para":
        return Para;
      case "reference":
        return Reference;
      case "root":
        return Root;
      case "title":
        return Title;
      case "warning":
        return Warning;
      case "table":
        return Table;
      case "randomList":
        return RandomList;
      case "sequentialList":
        return RandomList;
      default:
        return Unknown;
    }
  }

  renderS1000D(element) {
    const { type, children, ...elementProps } = element;

    return React.createElement(
      this.getTypeMapping(element.type),
      this.props.data.type === "image"
        ? { style: { overflowX: "scroll" } }
        : elementProps,
      children.length > 0
        ? children.map((child) => this.renderS1000D(child))
        : null
    );
  }

  render() {
    return (
      <div className="rendclass">{this.renderS1000D(this.props.data)}</div>
    );
  }
}

export default S1000D;
