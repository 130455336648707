import React from 'react';
import { withRouter } from 'react-router-dom';

/* Display anchor tag in the procedure documents */
const LexxAnchor = (props) => {

    /* Redirect to another procedure document */
    const redirectTo = (link) => {
        props.history.push(`/procedure/id=${link}`);
    };

    /* Loop over tag_data using map function and return button for redirection  */
    const mapTag = (obj) => {
        let anchors = obj.tag_data.map((anchor, key) => {
            return (
                <button
                    className='para-ref-btn'
                    onClick={() => {
                        redirectTo(props.anchorData.tag_data[0]);
                    }}
                    key={key}
                >
                    {anchor}
                </button>
            );
        });
        return (
            <React.Fragment>
                {anchors}
            </React.Fragment>
        );
    };

    return (
        <>
            {mapTag(props.anchorData)}
        </>
    );
};

export default withRouter(LexxAnchor);