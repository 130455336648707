import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'semantic-ui-react';

/* The component is for showing the user modal when there is no activity */
function LexxIdlePopup(props) {

  const [minutes, setMinute] = useState(2); // 2 minutes to be given to the user for clicking on "Stay"
  const [seconds, setSeconds] = useState(0); // Seconds

  useEffect(() => {   
    var intervalTime = setInterval(() => 
    {      
      if (seconds > 0) {
        setSeconds(seconds - 1); // reduce seconds on the modal as time passes
      }
      if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(intervalTime); // when both seconds 0 and minutes 0 clear Interval and logout
            props.handleLogout();
          } else {
            setMinute(minutes - 1);
            setSeconds(59);
          }
      }  
      }, 1000);     // update the seconds and minutes every second
      return () => {
        clearInterval(intervalTime);  
      }
  });


  /* Function called when the user clicks on Stay button 
    It resets the minutes and seconds */
  const stayInWebsite = () => {
    setMinute(2);
    setSeconds(0);
    props.handleClose();
  }

  return (
    <Modal onClose={() => props.handleClose} open={props.showModal}>
      <Modal.Header>You Have Been Idle! </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            You Will Get Timed Out. Time Remaining: {minutes}:{seconds < 10 ? `0${seconds}` : seconds} You want to stay?
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='red' onClick={props.handleLogout}>
          Logout
        </Button>
        <Button color='blue' onClick= {stayInWebsite}>
          Stay
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default LexxIdlePopup