import React, { useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import Webcam from "react-webcam";

/* The component is for showing the user modal when there is no activity */
function WebCam(props) {
  const [imageSrc, setImageSrc] = useState(null);

  const videoConstraints = {
    facingMode: "user",
  };

  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    const imageSrc2 = webcamRef.current.getScreenshot();

    setImageSrc(imageSrc2);
  }, [webcamRef]);

  const uploadImage = async () => {
    const binary = atob(imageSrc.split(",")[1]);
    const array = [];
    for (let i = 0; i < binary.length; i += 1) {
      array.push(binary.charCodeAt(i));
    }
    const file = new Blob([new Uint8Array(array)], {
      type: "image/png",
    });
    file.name = `${new Date()}.png`;
    file.lastModifiedDate = new Date();
    const myFile = new File([file], file.name,{
      type: file.type,
    });
    props.getCapturedImage(myFile,imageSrc);
    props.handleClose();
  };

  return (
    <Modal 
        onClose={() => props.handleClose} 
        open={props.showModal}
    >
      <Modal.Header>Capture Image </Modal.Header>
      <Modal.Content>
        {imageSrc ? (
          <img
            src={imageSrc}
            alt="profileImage"
            height={"100%"}
            width={"100%"}
          />
        ) : (
          <Webcam
            audio={false}
            height={"100%"}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={"100%"}
            videoConstraints={videoConstraints}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" 
        onClick={props.handleClose}
        >
          Cancel
        </Button>
        <Button color="blue" 
        onClick={() => (imageSrc ? uploadImage() : capture())}
        >
        {imageSrc ? 'Upload' : 'Capture'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default WebCam;
