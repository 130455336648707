import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-number-input/input';
import { Form, Button, Image } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from "axios";
import LexxButton from '../LexxButton/lexxbutton.jsx';
import { profileCompleted } from "../../redux/actions/index";
import { getProfile, getProfileImageLink, uploadProfileImage, profileUploaded, saveProfile } from "../apiCall";


/* This component is for editing the user profile */
function UserProfile(props) {

    const [form, setForm] = useState({
        name: "",
        title: "",
        aboutMe: "",
        city: "",
        country: "",
        speciality: [],
        phoneNum: "",
        profileImg: ""
    });
    const [file, setFile] = useState({}); // adding the profile photo
    const [error, setError] = useState("");


    /* Get the profile data of the user */
    const getProfileFn = () => {
        getProfile(props.requesterId).then((response) => {
            var profileData = response.data.data.user;
            setForm({
                name: profileData.name === undefined ? "" : profileData.name,
                title: profileData.title === undefined ? "" : profileData.title,
                aboutMe: profileData.aboutMe === undefined ? "" : profileData.aboutMe,
                city: profileData.location === undefined ? "" : profileData.location.city,
                country: profileData.location === undefined ? "" : profileData.location.country,
                speciality: profileData.speciality === undefined ? [] : profileData.speciality.join(' '),
                phoneNum: profileData.phoneNum === undefined ? "" : profileData.phoneNum,
                profileImg: profileData.profileImg === undefined ? "" : profileData.profileImg
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(getProfileFn, []);

    /* Call this function when there is any change to the form */
    const handleChange = e => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    /* This function is used to upload the Photo
        First we get the link to upload from S3
        then we push that image to s3
        and get a link back to show it in the UI
    */
    const uploadPhoto = () => {
        let fileName = file.name;
        getProfileImageLink(props.requesterId, fileName.slice(fileName.lastIndexOf("."))).then((result) => {
            if (result.data.code === 'Success') {
                delete axios.defaults.headers.common["Authorization"];
                var blob = file.slice(0, file.size, 'image/*');
                let newFile = new File([blob], props.requesterId + fileName.slice(fileName.lastIndexOf(".")), { type: 'image/*' });
                uploadProfileImage(result.data.data.url, newFile).then((response) => {
                    var data = { "key": props.requesterId + fileName.slice(fileName.lastIndexOf(".")) };
                    axios.defaults.headers.common["Authorization"] = localStorage.getItem("app_token");
                    profileUploaded(data).then((response) => {
                        if (response.data.code === 'Success') {
                            props.history.push("/userProfile");
                        }
                    })
                })
            } else {
                props.history.push("/userProfile");
            }
        })
    }


    /* The function is to validate the fields making sure if the fields are empty or not */
    const validate = () => {
        var field = checkProperties(form);
        if (field.length === 0) {
            return false;
        }
        switch (field) {
            case 'name':
                setError("Full Name cannot be empty");
                break;
            case 'title':
                setError("Role cannot be empty");
                break;
            case 'aboutMe':
                setError("My Bio cannot be empty");
                break;
            case 'city':
                setError("City cannot be empty");
                break;
            case 'country':
                setError("Country cannot be empty");
                break;
            case 'phoneNum':
                setError("Phone Number cannot be empty");
                break;
            default:
                setError("Speciality cannot be empty");
                break;
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        return true;
    }

    /* This function is a helper function for validate 
        It checks if the form obj keys and returns them 
    */
    const checkProperties = (obj) => {
        for (var key in obj) {
            if (obj[key].length === 0 && key !== 'profileImg') {
                return key;
            }
        }
        return "";
    }

    /* This function is called when the user has made edits in the profile and saved it
        it creates a formData obj from the fields and sends it to server
    */
    const saveProfileFn = () => {
        if (!validate()) {
            var formData = {
                "name": form.name,
                "title": form.title,
                "location": {
                    "country": form.country,
                    "city": form.city
                },
                "speciality": form.speciality.split(' '),
                "aboutMe": form.aboutMe,
                "phoneNum": form.phoneNum
            }
            saveProfile(formData).then((response) => {
                props.profileCompleted(true);
                if (file.name !== undefined) {
                    uploadPhoto();
                } else {
                    props.history.push("/userProfile");
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    /* This function is used for loading a new profile image from the user camera or storage
        @e: its the event returned from the UI and then later set
    */
    const loadFile = (e) => {
        setFile(e.target.files[0]);
        setForm({ ...form, profileImg: URL.createObjectURL(e.target.files[0]) });
    }

    return (
        <div className="edit-user-profile-container">
            <div className="image-wrapper">
                <Button as="label" htmlFor="file" type="button">
                    <Image id="output" width="200" src={form.profileImg} alt=" " hidden={form.profileImg.length < 1 ? true : false} />
                    {form.profileImg.length < 1 ?
                        <>
                            <i className="cloud icon"> </i>
                            Add Photo
                        </>
                        : null
                    }
                </Button>
                <input type="file" id="file" style={{ display: "none" }} onChange={loadFile} accept="image/*" />
            </div>
            <Form>
                {error.length > 0 ?
                    <div className="profile error" id="error">
                        <p>{error}</p>
                    </div>
                    : null}
                <Form.Field required>
                    <label>Full Name</label>
                    <input placeholder='Full Name' name="name" value={form.name} onChange={handleChange} />
                </Form.Field>
                <Form.Field required>
                    <label>Role</label>
                    <input placeholder='Role' name="title" value={form.title} onChange={handleChange} />
                </Form.Field>
                <Form.Field required>
                    <label>My Bio</label>
                    <textarea placeholder='My Bio' name="aboutMe" value={form.aboutMe} onChange={handleChange} rows="4" />
                </Form.Field>
                <Form.Field required>
                    <label>My City</label>
                    <input placeholder='My City' name="city" value={form.city} onChange={handleChange} />
                </Form.Field>
                <Form.Field required>
                    <label>My Country</label>
                    <input placeholder='My Country' name="country" value={form.country === '' ? setForm({ ...form, country: 'US' }): form.country} onChange={handleChange} />
                </Form.Field>
                <Form.Field required>
                    <label>My email</label>
                    <input placeholder='My Email' value={props.requesterId} disabled />
                </Form.Field>
                <Form.Field required>
                    <label>My Mobile Number</label>
                    <PhoneInput
                        placeholder="My Mobile Number"
                        value={form.phoneNum}
                        country="US"
                        onChange={(value) => setForm({ ...form, phoneNum: value })} />
                </Form.Field>
                <Form.Field required>
                    <label>My specialities</label>
                    <input placeholder='My specialities' name="speciality" value={form.speciality} onChange={handleChange} />
                </Form.Field>
                <LexxButton
                    primary={true}
                    text="Submit"
                    act={() => {
                        saveProfileFn();
                    }}
                />
            </Form>
        </ div>
    )
}


const mapStateToProps = (state) => {
    return {
        requesterId: state.userInfo.userId
    };
};


export default withRouter(connect(mapStateToProps, { profileCompleted })(UserProfile));
