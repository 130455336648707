import * as React from "react";
import { List } from "semantic-ui-react";

const ListItem = (props) => (
    <List.Item>
        {(props.children && props.children.length > 0) && props.children}
    </List.Item>
);

export default ListItem;
