import * as React from "react";

const Figure = (props) => (
    <>
        {(props.text && props.text.length > 0) && (
            <div style={{overflowX:'scroll'}} dangerouslySetInnerHTML={{__html: props.text}}></div>
        )}
    </>
);

export default Figure;
