import React from 'react';
import { useState } from 'react';
import { Sidebar, Menu, Icon, Image } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeSidebarVisibility } from '../../redux/actions';
import KeycloakUserService from '../login/KeyCloakService';
import { useEffect } from 'react';


/* This is the sidebar component for the app which various items */
const SidebarComp = (props) => {
  const [groupId, setgroupId] = useState(KeycloakUserService.getGroups());
  const [featureFlag, setFeatureFlag] = useState(props.featureFlag);

  useEffect(()=>{
    setFeatureFlag(props.featureFlag);
  },[props.featureFlag]);
  /* Function redirects the user to the specific page based on the item clicked */
  const clickMenu = (section) => {
    props.changeSidebarVisibility(props.sidebarVisible); // save in redux if the menu is open or not
    switch (section) {
      case 'team':
        props.history.push('/myteam');
        break;
      // case 'tasks':
      //   props.history.push('/allTasks');
      //   break;
      case 'sessions':
        props.history.push('/allSessions');
        break;
      case 'profile':
        props.history.push('/userProfile');
        break;
      case "newsolutions":
        props.history.push("/newsolutions");
        break;
      // case 'reports':
      //   props.history.push('/reports');
      //   break;
      // case 'qrcode':
      //   props.history.push('/qrcode');
      //   break;
      default:
        props.history.push('/');
        break;
    }
  }

  const renderAddsolution = () => {
    if (localStorage.getItem("projectGroup") === '/EDPRPQ' || localStorage.getItem("projectGroup") === '/EDPRPQ_dev') {
      return  (<Menu.Item as="a" onClick={() => clickMenu("newsolutions")}>
      <Icon name="plus circle" />
      Add a solution
    </Menu.Item>);
    } else {
      return (<Menu.Item as='a' onClick={null} className='disable-color'>
      <Icon name='plus circle' />
      Add a solution
    </Menu.Item>);
    }
  }

  return (

      <Sidebar
        as={Menu}
        animation='overlay'
        icon='labeled'
        inverted
        onHide={() => {
          props.changeSidebarVisibility(true);  // It's important to use the action from props
        }}
        vertical
        visible={props.sidebarVisible}
        width='thin'
      >
        <Menu.Item as='a' onClick={() => clickMenu('dashboard')} 
        className={!featureFlag?.dashboard ?'disable-color':''}
        >
          <Icon name='home' />
          Dashboard
        </Menu.Item>
        <Menu.Item as='a' onClick={() => clickMenu('team')} className={!featureFlag?.['my-team'] ?'disable-color':''}>
          <Icon name='users' />
          My Team
        </Menu.Item>
        <Menu.Item as='a' onClick={null} className={!featureFlag?.['my-schedule'] ?'disable-color':''}>
          <Icon name='calendar alternate outline' />
          My Schedule
        </Menu.Item>
        <Menu.Item as='a' onClick={null} className={!featureFlag?.['my-tasks'] ?'disable-color':''}>
          <Icon name='tasks' />
          My Tasks
        </Menu.Item>
        <Menu.Item as='a' onClick={() => clickMenu('sessions')} className={!featureFlag?.['my-sessions'] ?'disable-color':''}>
          <Icon name='chat' />
          My Sessions
        </Menu.Item>
        <Menu.Item as='a' onClick={null} className={!featureFlag?.['my-reports'] ?'disable-color':''}>
          <Icon name='wrench' />
          My Reports
        </Menu.Item>
        <Menu.Item as='a' onClick={null} className={!featureFlag?.['tools'] ?'disable-color':''}>
          <Icon name='wrench' />
          Tools
        </Menu.Item>
        <Menu.Item as='a' onClick={() => clickMenu('profile')} className={!featureFlag?.['my-profile'] ?'disable-color':''}>
          <Icon name='user outline' />
          My Profile
        </Menu.Item>
        <Menu.Item as='a' onClick={null} className={!featureFlag?.['qr-scanner'] ?'disable-color':''}>
          <Icon name='qrcode' />
          QR Scanner
        </Menu.Item>
        <Menu.Item as='a' onClick={null} className={!featureFlag?.settings ?'disable-color':''}>
          <Icon name='setting' />
          Settings
        </Menu.Item>
        {
          localStorage.getItem("projectGroup") === '/EDPRPQ' ? 
          <Menu.Item as="a" onClick={() => clickMenu("newsolutions")} className={!featureFlag?.['add-a-solution'] ?'disable-color':''}>
            <Icon name="plus circle" />
            Add a solution
          </Menu.Item> : 
          <Menu.Item as="a" className={!featureFlag?.['add-a-solution'] ?'disable-color':''}>
            <Icon name="plus circle" />
            Add a solution
          </Menu.Item>
        }
        
        {/* {renderAddsolution()} */}

        <div className="energyLogo-Wrapper">
          <Image src={require("../../assets/images/lexx-energy-logo.png")} />
        </div>
      </Sidebar>
  );
};

const mapStateToProps = (state) => {
  return {
    sidebarVisible: state.sidebarVisible,
    featureFlag: state.featureFlag,
  }
}

export default withRouter(connect(mapStateToProps, {
  changeSidebarVisibility,
})(SidebarComp));